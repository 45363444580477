define('bc-frontend/components/verb-chart/verb/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    style: Ember.computed('offset', function () {
      var offset = this.get('offset');
      return Ember.String.htmlSafe('left: ' + offset + '%;');
    }),

    actions: {
      selectSegment: function selectSegment(segment) {
        this.sendAction('onSelectSegment', segment);
      }
    }
  });
});