define('bc-frontend/components/paper-data-table-body', ['exports', 'paper-data-table/components/paper-data-table-body'], function (exports, _paperDataTableBody) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperDataTableBody.default;
    }
  });
});