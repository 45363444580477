define('bc-frontend/components/schema-tile/calibration/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: null,
    actions: {
      visitCalibration: function visitCalibration() {
        this.get('router').transitionTo('test.calibration', this.get('test.id'), 1);
      }
    }
  });
});