define('bc-frontend/components/dataset-detail/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: null,

    dataSet: null,
    schema: Ember.computed.alias('dataSet.schema'),
    schemaSlug: Ember.computed.alias('schema.slug'),

    tileKey: Ember.computed('schemaSlug', function () {
      var schemaSlug = this.get('schemaSlug');
      if (schemaSlug) {
        return 'dataset-detail/layouts/' + schemaSlug;
      }
      return null;
    }),

    actions: {
      modifiedChanged: function modifiedChanged(flag) {
        this.sendAction('onModifiedChanged', flag);
      }
    }
  });
});