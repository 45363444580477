define('bc-frontend/test/zone-sequence/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({
    testController: inject.controller('test'),
    test: computed.alias('testController.model'),

    dataSets: computed.alias('model'),
    dataSetsCount: computed.alias('dataSets.length'),
    hasData: computed.gte('dataSetsCount', 1),

    showCollectButton: computed.alias('model.meta.canCollect'),

    actions: {
      generateModalClosed: function generateModalClosed() {
        this.get('target.router').refresh();
        this.set('showGenerateAnalysis', false);
      },
      onRefresh: function onRefresh() {
        this.get('target.router').refresh();
      }
    }
  });
});