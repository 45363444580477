define('bc-frontend/components/collaborator-tile/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userSession: Ember.inject.service(),

    displayPerm: Ember.computed('collaborator.permissions', function () {
      var permissions = this.get('collaborator.permissions');

      if (permissions.indexOf('delete_test') >= 0 || permissions.indexOf('manage_test') >= 0) {
        return 'Admin';
      } else if (permissions.indexOf('change_test') >= 0) {
        return 'Read/Write';
      } else {
        return 'Read Only';
      }
    }),

    isMe: Ember.computed('userSession.userId', 'collaborator.id', function () {
      var userId = this.get('userSession.userId'),
          collaboratorId = this.get('collaborator.id');

      return userId == collaboratorId;
    }),

    actions: {
      setPermission: function setPermission(permission) {
        var collaborator = this.get('collaborator');
        this.sendAction('onChange', collaborator, permission);
      },
      removeCollaborator: function removeCollaborator() {
        var collaborator = this.get('collaborator');
        this.sendAction('onRemove', collaborator);
      }
    }
  });
});