define('bc-frontend/components/time-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    minutes: Ember.computed('time', function () {
      return parseInt(this.get('time') / 60);
    }),
    seconds: Ember.computed('time', function () {
      return this.get('time') % 60;
    })
  });
});