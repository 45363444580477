define('bc-frontend/initializers/key-manager-config', ['exports', 'bc-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var get = Ember.get;
  function initialize(application) {
    var keyManagerConfig = get(_environment.default, 'keyManagerConfig') || {};
    application.register('main:key-manager-config', keyManagerConfig, {
      instantiate: false
    });
  }

  exports.default = {
    name: 'key-manager-config',
    initialize: initialize
  };
});