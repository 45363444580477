define('bc-frontend/components/zonemap-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_WIDTH = 640,
      DEFAULT_HEIGHT = 480;

  fabric.Rect.prototype.toSVG = function (toSVG) {
    return function () {
      if (this.excludeFromAlternation) {
        return $(toSVG.call(this)).attr('excludeFromAlternation', true)[0].outerHTML;
      }
      return toSVG.call(this);
    };
  }(fabric.Rect.prototype.toSVG);

  fabric.Triangle.prototype.toSVG = function (toSVG) {
    return function () {
      if (this.excludeFromAlternation) {
        return $(toSVG.call(this)).attr('excludeFromAlternation', true)[0].outerHTML;
      }
      return toSVG.call(this);
    };
  }(fabric.Triangle.prototype.toSVG);

  fabric.Polygon.prototype.toSVG = function (toSVG) {
    return function () {
      if (this.excludeFromAlternation) {
        return $(toSVG.call(this)).attr('excludeFromAlternation', true)[0].outerHTML;
      }
      return toSVG.call(this);
    };
  }(fabric.Polygon.prototype.toSVG);

  fabric.Circle.prototype.toSVG = function (toSVG) {
    return function () {
      if (this.excludeFromAlternation) {
        return $(toSVG.call(this)).attr('excludeFromAlternation', true)[0].outerHTML;
      }
      return toSVG.call(this);
    };
  }(fabric.Circle.prototype.toSVG);

  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['zonemap-editor'],
    backgroundUri: null,
    existingMap: null,
    activeZoneLabel: '',
    activeZoneExcluded: false,
    activeObject: null,
    disabled: false,

    fabricCanvas: null,

    _setup: function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        _this._setupFabric();
        _this.loadBackground();
        _this.loadMap();
        _this.registerWithParent();
      });
    }.on('didInsertElement'),

    _setupFabric: function _setupFabric() {
      if (Ember.isEmpty(this.get('fabricCanvas'))) {
        var canvas = new window.fabric.Canvas(this.$('canvas')[0]);
        canvas.setDimensions({ width: null, height: null }, { cssOnly: true });
        canvas.setDimensions({ width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT }, { backstoreOnly: true });
        canvas.centeredScaling = false;
        canvas.renderOnAddRemove = false;
        this.set('fabricCanvas', canvas);
      }
    },


    _mapUpdated: Ember.computed('existingMap', function () {
      var existingMap = this.get('existingMap'),
          fabricCanvas = this.get('fabricCanvas');

      if (existingMap && fabricCanvas) {
        this.loadMap();
      }
    }),

    loadBackground: function loadBackground() {
      var fabricCanvas = this.get('fabricCanvas'),
          backgroundUri = this.get('backgroundUri');

      if (!fabricCanvas || !backgroundUri) {
        return;
      }

      fabricCanvas.setBackgroundImage(backgroundUri, this.afterBackground.bind(this, fabricCanvas), {
        // Needed to position backgroundImage at 0/0
        originX: 'left',
        originY: 'top'
      });
    },
    afterBackground: function afterBackground(canvas) {
      canvas.setDimensions({
        width: canvas.backgroundImage.width,
        height: canvas.backgroundImage.height
      }, { backstoreOnly: true });
      canvas.renderAll();
    },
    loadMap: function loadMap() {
      var self = this,
          mapUri = this.get('existingMap'),
          fabricCanvas = this.get('fabricCanvas');

      if (!mapUri || !fabricCanvas) {
        self.addObservers();
        return;
      }

      window.fabric.loadSVGFromURL(mapUri, function (objects) {
        objects.forEach(function (object) {
          fabricCanvas.add(object);
          object.setCoords();
        });
        fabricCanvas.renderAll();
        self.addObservers();
      }, function (element, object) {
        var transformObject = getObjectTransformer();
        transformObject(object, element.getAttribute('transform'));
        if (element.getAttribute('excludefromalternation')) {
          object.excludeFromAlternation = element.getAttribute('excludefromalternation');
        }
        return object;
      });
    },
    addObservers: function addObservers() {
      var fabricCanvas = this.get('fabricCanvas');

      if (!fabricCanvas) {
        return;
      }

      fabricCanvas.on('object:added', this.canvasUpdated.bind(this));
      fabricCanvas.on('object:modified', this.canvasUpdated.bind(this));
      fabricCanvas.on('object:selected', this.selectionCreated.bind(this));
      fabricCanvas.on('selection:created', this.selectionCreated.bind(this));
      fabricCanvas.on('selection:cleared', this.selectionCleared.bind(this));
    },
    canvasUpdated: function canvasUpdated() {
      this.sendAction('onChange');
    },
    selectionCreated: function selectionCreated() {
      var canvas = this.get('fabricCanvas');
      this.set('activeObject', canvas.getActiveObject());
      this.set('activeZoneLabel', canvas.getActiveObject().id);
      this.set('activeZoneExcluded', canvas.getActiveObject().excludeFromAlternation);
    },
    selectionCleared: function selectionCleared() {
      this.set('activeZoneLabel', '');
      this.set('activeZoneExcluded', false);
      this.set('activeObject', null);
    },
    updateZoneLabel: function updateZoneLabel(label) {
      this.set('activeZoneLabel', label);
      var canvas = this.get('fabricCanvas');
      canvas.getActiveObject().id = label;
    },
    updateZoneExcluded: function updateZoneExcluded(state) {
      this.set('activeZoneExcluded', state);
      var canvas = this.get('fabricCanvas');
      canvas.getActiveObject().excludeFromAlternation = state;
    },
    registerWithParent: function registerWithParent() {
      this.sendAction('onRegister', this);
    },
    randomColor: function randomColor() {
      return '#' + Math.floor(Math.random() * 16777215).toString(16);
    },
    createShape: function createShape(shape) {
      var fabricCanvas = this.get('fabricCanvas'),
          shapeObj = null,
          centerX = fabricCanvas.backgroundImage.width / 2,
          centerY = fabricCanvas.backgroundImage.height / 2;

      if (!fabricCanvas) {
        return;
      }

      if (shape === 'circle') {
        shapeObj = new fabric.Circle({
          radius: 50,
          fill: this.randomColor(),
          opacity: 0.8,
          left: centerX,
          top: centerY
        });
      } else if (shape === 'square') {
        shapeObj = new fabric.Rect({
          width: 100,
          height: 100,
          fill: this.randomColor(),
          opacity: 0.8,
          left: centerX,
          top: centerY
        });
      } else if (shape === 'triangle') {
        shapeObj = new fabric.Triangle({
          width: 100,
          height: 100,
          fill: this.randomColor(),
          opacity: 0.8,
          left: centerX,
          top: centerY
        });
      }

      var numObjects = fabricCanvas._objects.length + 1;
      shapeObj.id = 'Unlabeled' + numObjects;

      fabricCanvas.add(shapeObj);
      fabricCanvas.setActiveObject(shapeObj);
      fabricCanvas.renderAll();
    },
    deleteSelection: function deleteSelection() {
      var fabricCanvas = this.get('fabricCanvas');

      if (!fabricCanvas) {
        return;
      }

      var activeObject = fabricCanvas.getActiveObject();

      if (activeObject) {
        activeObject.remove();
        fabricCanvas.renderAll();
      }
    },
    bumpSelection: function bumpSelection(direction) {
      var fabricCanvas = this.get('fabricCanvas');

      if (!fabricCanvas) {
        return;
      }

      var activeObject = fabricCanvas.getActiveObject();

      if (activeObject) {
        switch (direction) {
          case "up":
            activeObject.setTop(activeObject.getTop() - 1);
            break;
          case "right":
            activeObject.setLeft(activeObject.getLeft() + 1);
            break;
          case "down":
            activeObject.setTop(activeObject.getTop() + 1);
            break;
          case "left":
            activeObject.setLeft(activeObject.getLeft() - 1);
            break;
        }
      }

      fabricCanvas.renderAll();
    },
    createClone: function createClone() {
      var fabricCanvas = this.get('fabricCanvas'),
          self = this;

      if (!fabricCanvas) {
        return;
      }

      var activeObject = fabricCanvas.getActiveObject();

      if (activeObject) {
        activeObject.clone(function (obj) {
          obj.setLeft(obj.getLeft() + 10);
          obj.setTop(obj.getTop() + 10);
          obj.setFill(self.randomColor());
          var numObjects = fabricCanvas._objects.length + 1;
          obj.id = 'Unlabeled' + numObjects;
          fabricCanvas.add(obj);
          fabricCanvas.setActiveObject(obj);
          fabricCanvas.renderAll();
        });
      }
    },


    /* To support deleting using delete/backspace */
    didRender: function didRender() {
      this.$().attr({ tabindex: 1 });
    },

    keyDown: function keyDown(event) {
      if (document.activeElement.classList.contains('zonemap-editor')) {
        if (event.keyCode === 8 || event.keyCode === 46) {
          this.deleteSelection();
          return false;
        } else if (event.keyCode === 37) {
          this.bumpSelection('left');
          return false;
        } else if (event.keyCode === 38) {
          this.bumpSelection('up');
          return false;
        } else if (event.keyCode === 39) {
          this.bumpSelection('right');
          return false;
        } else if (event.keyCode === 40) {
          this.bumpSelection('down');
          return false;
        }
      }
    },


    /* Export functions */
    exportSVG: function exportSVG() {
      var fabricCanvas = this.get('fabricCanvas'),
          width = fabricCanvas.backgroundImage.width,
          height = fabricCanvas.backgroundImage.height;

      if (!fabricCanvas) {
        return;
      }

      return fabricCanvas.toSVG({
        width: width,
        height: height,
        viewBox: {
          x: 0,
          y: 0,
          width: width,
          height: height
        }
      }, function (svg) {
        if (svg.indexOf('<image') >= 0) {
          return '';
        }
        return svg;
      });
    },
    validateMap: function validateMap() {
      var fabricCanvas = this.get('fabricCanvas');

      if (!fabricCanvas) {
        return;
      }

      var all_ids = fabricCanvas._objects.map(function (obj) {
        return obj.id;
      });
      var non_empty_ids = all_ids.filter(function (id) {
        return id !== undefined && id !== null && id !== '';
      });
      var unique_ids = all_ids.uniq();

      return non_empty_ids.length === all_ids.length && unique_ids.length === all_ids.length;
    },


    actions: {
      createCircle: function createCircle() {
        this.createShape('circle');
      },
      createSquare: function createSquare() {
        this.createShape('square');
      },
      createTriangle: function createTriangle() {
        this.createShape('triangle');
      },
      createClone: function createClone() {
        this.createClone();
      },
      deleteSelection: function deleteSelection() {
        this.deleteSelection();
      },
      saveMap: function saveMap() {},
      updateZoneLabel: function updateZoneLabel(label) {
        this.updateZoneLabel(label);
      },
      updateZoneExcluded: function updateZoneExcluded(state) {
        this.updateZoneExcluded(state);
      }
    }
  });


  function getObjectTransformer() {
    var number = fabric.reNum,
        commaWsp = '(?:\\s+,?\\s*|,\\s*)',
        skewX = '(?:(skewX)\\s*\\(\\s*(' + number + ')\\s*\\))',
        skewY = '(?:(skewY)\\s*\\(\\s*(' + number + ')\\s*\\))',
        rotate = '(?:(rotate)\\s*\\(\\s*(' + number + ')(?:' + commaWsp + '(' + number + ')' + commaWsp + '(' + number + '))?\\s*\\))',
        scale = '(?:(scale)\\s*\\(\\s*(' + number + ')(?:' + commaWsp + '(' + number + '))?\\s*\\))',
        translate = '(?:(translate)\\s*\\(\\s*(' + number + ')(?:' + commaWsp + '(' + number + '))?\\s*\\))',
        matrix = '(?:(matrix)\\s*\\(\\s*' + '(' + number + ')' + commaWsp + '(' + number + ')' + commaWsp + '(' + number + ')' + commaWsp + '(' + number + ')' + commaWsp + '(' + number + ')' + commaWsp + '(' + number + ')' + '\\s*\\))',
        transform = '(?:' + matrix + '|' + translate + '|' + scale + '|' + rotate + '|' + skewX + '|' + skewY + ')',
        transforms = '(?:' + transform + '(?:' + commaWsp + '*' + transform + ')*' + ')',
        transformList = '^\\s*(?:' + transforms + '?)\\s*$',

    // http://www.w3.org/TR/SVG/coords.html#TransformAttribute
    reTransformList = new RegExp(transformList),

    // == end transform regexp
    reTransform = new RegExp(transform, 'g');
    return function (obj, attributeValue) {
      // return if no argument was given or
      // an argument does not match transform attribute regexp
      if (!attributeValue || attributeValue && !reTransformList.test(attributeValue)) {
        return;
      }

      var offset = [0, 0],
          scale = [1, 1],
          rotation = 0;

      attributeValue.replace(reTransform, function (match) {
        var m = new RegExp(transform).exec(match).filter(function (match) {
          // match !== '' && match != null
          return !!match;
        }),
            operation = m[1],
            args = m.slice(2).map(parseFloat);
        switch (operation) {
          case 'translate':
            offset[0] += args[0];
            offset[1] += args[1];
            break;
          case 'rotate':
            rotation += args[0];
            break;
          case 'scale':
            scale = [args[0], args[1]];
            break;
          case 'skewX':
            //skewXMatrix(matrix, args);
            break;
          case 'skewY':
            //skewYMatrix(matrix, args);
            break;
        }
      });

      obj.setScaleX(scale[0]);
      obj.setScaleY(scale[1]);
      obj.left = obj.left * scale[0];
      obj.top = obj.top * scale[1];

      obj.rotate(rotation);

      obj.left = obj.left + offset[0];
      obj.top = obj.top + offset[1];

      obj.transformMatrix = null;

      obj.setCoords();
      return;
    };
  }
});