define('bc-frontend/test/manual-setup/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({
    ajax: inject.service(),

    testController: inject.controller('test'),
    test: computed.alias('testController.model'),

    latestConfiguration: computed.alias('model.firstObject'),
    dataSets: computed.alias('model'),
    dataSetsCount: computed.alias('dataSets.length'),
    hasData: computed.gte('dataSetsCount', 1),

    editorComponent: null,
    configurationModified: false,
    preventTransition: Ember.computed.alias('configurationModified'),
    isSaving: false,

    saveConfiguration: function saveConfiguration() {
      var self = this,
          testId = this.get('test.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/tests/' + testId + '/data',
          editorComponent = this.get('editorComponent'),
          data = editorComponent.exportData();

      var payload = {
        data: data,
        schema: 'manual-scoring-setup'
      };

      this.set('isSaving', true);

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.set('configurationModified', false);
        self.set('isSaving', false);
      }, function () {
        alert('Failed to save configuration. Please try again momentarily.');
        self.set('isSaving', false);
      });
    },


    actions: {
      registerEditor: function registerEditor(editor) {
        this.set('editorComponent', editor);
      },
      saveConfiguration: function saveConfiguration() {
        this.saveConfiguration();
      }
    }
  });
});