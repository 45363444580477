define('bc-frontend/components/manual-scoring-configuration-editor/edit-behavior-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    label: Ember.computed.reads('behavior.label'),

    actions: {
      confirm: function confirm() {
        var behavior = this.get('behavior'),
            label = this.get('label');

        this.sendAction('onUpdate', behavior, label);
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});