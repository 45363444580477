define('bc-frontend/components/dataset-detail/components/video-bound-adjustment/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    ajaxNoAuth: Ember.inject.service(),
    userSession: Ember.inject.service(),

    model: null,
    url: Ember.computed.reads('model.url'),
    test: Ember.computed.alias('model.test'),
    testSchema: Ember.computed.alias('test.schema'),
    testSchemaSlug: Ember.computed.alias('testSchema.slug'),

    testRequiresCleared: Ember.computed.equal('testSchemaSlug', 'video_auto'),

    src: Ember.computed('url', function () {
      return {
        src: this.get('url'),
        type: 'video/mp4'
      };
    }),

    playerSetup: {
      controls: true
    },

    dataModified: false,

    dataModifiedObserver: Ember.observer('dataModified', function () {
      this.sendAction('onModifiedChanged', this.get('dataModified'));
    }),

    subject: Ember.computed.reads('model.subject'),
    isSaving: false,
    videoDuration: null,
    showUpgradePrompt: false,

    canSave: Ember.computed('isSaving', 'videoDuration', function () {
      var isSaving = this.get('isSaving'),
          videoDuration = this.get('videoDuration');
      return videoDuration !== null && !isSaving;
    }),

    position: 0.0,
    duration: 0.0,
    cleared: 0.0,
    inserted: 0.0,

    player: null,

    // _setup: function() {
    // }.on('didInsertElement'),

    trySaveData: function trySaveData() {
      var _this = this;

      var self = this;

      this.set('isSaving', true);

      this.checkOverage(this.get('videoDuration')).then(function (proceed) {
        if (proceed) {
          _this.saveData();
        } else {
          _this.set('isSaving', false);
        }
      });
    },
    saveData: function saveData() {
      this.set('isSaving', true);

      var clearing = (0, _moment.default)();

      var model = this.get('model');
      model.set('clearing', clearing.toDate());
      model.set('inserting', (0, _moment.default)(clearing).add(this.get('cleared'), 'seconds').toDate());
      model.set('collecting', (0, _moment.default)(clearing).add(this.get('inserted'), 'seconds').toDate());
      model.set('complete', (0, _moment.default)(clearing).add(this.get('duration'), 'seconds').toDate());
      model.set('subject', this.get('subject'));
      model.set('duration', this.get('duration') - this.get('inserted'));

      // model.save().then(function() {
      //   self.set('dataModified', false);
      //   self.set('isSaving', false);
      // });

      /* TEMPORARY WORKAROUND UNTIL NORMAL PUT/PATCH-BASED SAVES
         ARE WORKING */
      var ajax = this.get('ajax'),
          dsId = model.get('id');
      ajax.request('/api/dataSets/' + dsId + '/meta', {
        method: 'put',
        data: JSON.stringify({ dataSet: model.serialize() }),
        contentType: 'application/json'
      }).then(function () {
        model.reload();
      });
    },
    checkOverage: function checkOverage(duration) {
      var self = this,
          ajax = this.get('ajax'),
          userId = this.get('userSession.userId');

      return ajax.request('/api/users/' + userId + '/get_overage_cost?time=' + duration).then(function (response) {
        var upgradeNeeded = response.user.upgradeNeeded;

        if (upgradeNeeded) {
          self.set('showUpgradePrompt', true);
          return false;
        } else {
          return true;
        }
      }, function (error) {
        if (error.errors[0].status === "400") {
          self.set('showUpgradePrompt', true);
          return false;
        }
        alert("We were unable to communicate with the server. Please try again momentarily.");
        return false;
      });
    },
    processStripeToken: function processStripeToken(token) {
      var self = this,
          ajax = this.get('ajax'),
          userId = this.get('userSession.userId'),
          url = '/api/users/' + userId + '/create_card',
          payload = {
        user: {
          card: token.id
        }
      };

      ajax.request(url, {
        method: 'post',
        contentType: 'application/json',
        data: JSON.stringify(payload)
      }).then(function () {
        self.set('showUpgradePrompt', false);
        self.saveData();
      }, function () {
        alert("Failed to subscribe. Please try again shortly.");
      });
    },


    actions: {
      setPosition: function setPosition(position) {
        this.set('position', position);
      },
      setDuration: function setDuration(duration) {
        this.set('duration', duration);
        this.set('videoDuration', duration);
      },
      saveData: function saveData() {
        this.trySaveData();
      },
      playerReady: function playerReady(player, component) {
        this.set('player', player);
        component.sendActionOnPlayerEvent(player, 'timeupdate');
        component.sendActionOnPlayerEvent(player, 'durationchange');
        component.sendActionOnPlayerEvent(player, 'loadeddata');
      },
      playerTimeUpdate: function playerTimeUpdate(player) {
        var duration = player.duration();
        if (duration && !this.get('duration')) {
          this.send('setDuration', duration);
        }
        this.send('setPosition', player.currentTime());
      },
      playerDurationChange: function playerDurationChange(player) {
        var duration = player.duration();
        this.send('setDuration', duration);
      },
      playerLoadedData: function playerLoadedData(player) {
        player.hasStarted(true);
      },
      setClear: function setClear() {
        this.set('cleared', this.get('position'));
      },
      setInsert: function setInsert() {
        this.set('inserted', this.get('position'));
      },
      scrollCleared: function scrollCleared(value) {
        this.set('cleared', value);
        this.get('player').currentTime(value);
        if (this.get('inserted') < value) {
          this.set('inserted', value);
        }
      },
      scrollInserted: function scrollInserted(value) {
        this.set('inserted', value);
        this.get('player').currentTime(value);
        if (this.get('cleared') > value) {
          this.set('cleared', value);
        }
      },
      processStripeToken: function processStripeToken(token) {
        this.processStripeToken(token);
      },
      cancel: function cancel() {
        this.set('showUpgradePrompt', false);
      }
    }
  });
});