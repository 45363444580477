define('bc-frontend/components/dataset-detail/components/photobeam-grid/component', ['exports', 'ember-data', 'npm:base64-js', 'moment'], function (exports, _emberData, _npmBase64Js, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function hexStringToBinArray(str) {
    if (!str) {
      return new []();
    }

    var a = [];

    for (var i = 0, len = str.length; i < len; i += 1) {
      var bin = parseInt(str.substr(i, 1), 16);

      for (var mask = 8; mask > 0; mask >>= 1) {
        a.push(!!(bin & mask));
      }
    }

    return a;
  }

  exports.default = Ember.Component.extend({
    ajaxNoAuth: Ember.inject.service(),

    isPlaying: false,
    playbackTimer: null,
    playStartTime: null,
    playOffsetTime: null,

    activeSample: Ember.computed('model.isReceiving', 'model.lastSample', 'dataRows', 'dataRows.isFulfilled', 'sampleIndex', function () {
      if (this.get('model.isReceiving')) {
        return this.get('model.lastSample');
      }

      var dataRows = this.get('dataRows');

      if (dataRows && dataRows.get('isFulfilled')) {
        var sampleIndex = this.get('sampleIndex');
        return dataRows.get('content.' + sampleIndex);
      }

      return {};
    }),
    activeSampleTime: Ember.computed.alias('activeSample.t'),
    activeSampleRelativeTime: Ember.computed('activeSampleTime', 'firstSampleTime', function () {
      var activeSampleTime = this.get('activeSampleTime'),
          firstSampleTime = this.get('firstSampleTime');

      if (activeSampleTime || firstSampleTime) {
        return (0, _moment.default)(activeSampleTime).diff((0, _moment.default)(firstSampleTime));
      }
    }),
    displayTime: Ember.computed('activeSampleRelativeTime', function () {
      return _moment.default.duration(this.get('activeSampleRelativeTime')).format("HH:mm:ss.SSS", { trim: false });
    }),

    totalSamples: Ember.computed.alias('dataRows.length'),
    minSampleIndex: Ember.computed('dataRows', 'dataRows.isFulfilled', 'firstSampleTime', function () {
      var dataRows = this.get('dataRows'),
          firstSampleTime = (0, _moment.default)(this.get('firstSampleTime'));

      var minIndex = 0;

      if (!dataRows.get('isFulfilled')) return minIndex;

      dataRows.every(function (dataRow, dataIndex) {
        var st = (0, _moment.default)(dataRow.t);

        if (st >= firstSampleTime) {
          minIndex = dataIndex;
          return false;
        }
        return true;
      });

      return minIndex;
    }),
    maxSampleIndex: Ember.computed('totalSamples', function () {
      var totalSamples = this.get('totalSamples');
      if (totalSamples) {
        return totalSamples - 1;
      }
    }),
    sampleIndex: Ember.computed.reads('minSampleIndex'),

    // firstSample: Ember.computed.alias('dataRows.firstObject'),
    // firstSampleTime: Ember.computed.alias('firstSample.t'),
    firstSampleTime: Ember.computed.alias('model.collecting'),

    dataRows: Ember.computed('model.url', function () {
      var url = this.get('model.url');

      if (!url) {
        return null;
      }

      var ajax = this.get('ajaxNoAuth');

      var ajaxPromise = ajax.request(url).then(function (result) {
        return result;
      }, function () {
        return Ember.A([]);
      });

      return _emberData.default.PromiseArray.create({
        promise: ajaxPromise
      });
    }),

    isWaiting: Ember.computed('activeSample', function () {
      return Ember.keys(this.get('activeSample')).length === 0;
    }),

    displayData: Ember.computed('activeSample', function () {
      var activeSample = this.get('activeSample');

      // TODO: render some stuff
      var oMap = function oMap(o, f) {
        return Object.assign.apply(Object, _toConsumableArray(Object.keys(o).map(function (k) {
          return _defineProperty({}, k, f(o[k]));
        })));
      };

      return oMap(activeSample, function (x) {
        return hexStringToBinArray(x);
      });
    }),

    startPlayback: function startPlayback() {
      this.setProperties({
        playStartTime: this.get('activeSampleTime'),
        playOffsetTime: 0
      });
      var timer = Ember.run.later(this, this.playbackTimerCallback, 100);
      this.set('playbackTimer', timer);
    },
    stopPlayback: function stopPlayback() {
      Ember.run.cancel(this.get('playbackTimer'));
    },
    playbackTimerCallback: function playbackTimerCallback() {
      var playOffsetTime = this.get('playOffsetTime') + 0.1,
          playStartTime = (0, _moment.default)(this.get('playStartTime')),
          maxSampleIndex = this.get('maxSampleIndex'),
          sampleIndex = this.get('sampleIndex'),
          dataRows = this.get('dataRows');

      this.set('playOffsetTime', playOffsetTime);
      playStartTime.add(playOffsetTime, 's');

      var sample = this.get('activeSample');
      while (playStartTime > (0, _moment.default)(sample.t) && sampleIndex < maxSampleIndex) {
        sampleIndex++;
        sample = dataRows.get('content.' + sampleIndex);
      }

      this.set('sampleIndex', sampleIndex);

      if (sampleIndex < maxSampleIndex) {
        var timer = Ember.run.later(this, this.playbackTimerCallback, 100);
        this.set('playbackTimer', timer);
      } else {
        this.set('isPlaying', false);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var isPlaying = this.get('isPlaying'),
          playbackTimer = this.get('playbackTimer');

      if (isPlaying) Ember.run.cancel(playbackTimer);

      this._super.apply(this, arguments);
    },


    actions: {
      togglePlay: function togglePlay() {
        var isPlaying = !this.get('isPlaying');

        if (isPlaying) {
          // start the timer callback
          this.startPlayback();
        } else {
          // stop the timer callback
          this.stopPlayback();
        }

        this.set('isPlaying', isPlaying);
      },
      setIndex: function setIndex(index) {
        if (this.get('isPlaying')) this.send('togglePlay');

        this.set('sampleIndex', index);
      }
    }
  });
});