define('bc-frontend/components/tests/archive-test-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      Component = Ember.Component;
  exports.default = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),
    store: inject.service(),

    isPending: false,

    archiveTest: function archiveTest() {
      var self = this,
          modelId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/tests/' + modelId + '/archive';

      this.set('isPending', true);

      var payload = {};

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        // self.get('model').reload();
        self.send('close');
        self.get('model').unloadRecord();
      }, function () {
        alert('Failed to archive test. Please try again momentarily.');
      }).finally(function () {
        self.set('isPending', false);
      });
    },


    actions: {
      close: function close() {
        this.sendAction('onClose');
      },
      confirm: function confirm() {
        this.archiveTest();
      }
    }
  });
});