define('bc-frontend/helpers/format-date', ['exports', 'ember-string-helpers/helpers/format-date'], function (exports, _formatDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formatDate.default;
    }
  });
  Object.defineProperty(exports, 'formatDate', {
    enumerable: true,
    get: function () {
      return _formatDate.formatDate;
    }
  });
});