define('bc-frontend/shared/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortedExperiments: Ember.computed.sort('model', function (a, b) {
      var aId = parseInt(a.get('id')),
          bId = parseInt(b.get('id'));

      if (aId > bId) {
        return -1;
      } else if (aId < bId) {
        return 1;
      }

      return 0;
    })
  });
});