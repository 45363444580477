define('bc-frontend/test/dataset/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'bc-frontend/mixins/data-set-route-mixin', 'bc-frontend/mixins/prevent-transition-mixin'], function (exports, _authenticatedRouteMixin, _dataSetRouteMixin, _preventTransitionMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _dataSetRouteMixin.default, _preventTransitionMixin.default, {
    model: function model(params) {
      return this.get('store').findRecord('dataSet', params.dataset_id);
    }
  });
});