define('bc-frontend/components/tests/next-data-set-dialog/component', ['exports', 'bc-frontend/data-set/modern-schemas'], function (exports, _modernSchemas) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      Component = Ember.Component;
  exports.default = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),
    store: inject.service(),
    userSession: inject.service(),

    device: null,
    duration: null,

    showUpgradePrompt: false,

    checkOverage: function checkOverage() {
      var self = this,
          ajax = this.get('ajax'),
          duration = this.get('duration'),
          userId = this.get('userSession.userId');

      ajax.request('/api/users/' + userId + '/get_overage_cost?time=' + duration).then(function (response) {
        var upgradeNeeded = response.user.upgradeNeeded;

        if (upgradeNeeded) {
          self.set('showUpgradePrompt', true);
        } else {
          self.createDataSet();
        }
      }, function (error) {
        if (error.errors[0].status === "400") {
          self.set('showUpgradePrompt', true);
          return;
        }
        alert("We were unable to communicate with the server. Please try again momentarily.");
      });
    },
    processStripeToken: function processStripeToken(token) {
      var self = this,
          ajax = this.get('ajax'),
          userId = this.get('userSession.userId'),
          url = '/api/users/' + userId + '/create_card',
          payload = {
        user: {
          card: token.id
        }
      };

      ajax.request(url, {
        method: 'post',
        contentType: 'application/json',
        data: JSON.stringify(payload)
      }).then(function () {
        self.createDataSet();
      }, function () {
        alert("Failed to subscribe. Please try again shortly.");
      });
    },
    createDataSet: function createDataSet() {
      var self = this,
          deviceId = this.get('device.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + deviceId + '/create_observer_dataset';

      var payload = {
        dataSet: {
          test: self.get('test.id'),
          subject: self.get('subject')
        }
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function (recordJson) {
        self.captureDataSet(self.get('store').pushPayload(recordJson)[0]);
      }, function () {
        alert('failed!');
      });
    },
    captureDataSet: function captureDataSet(record) {
      var self = this,
          recordId = record.get('id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/capture';

      var payload = {
        dataSet: {
          settings: {
            duration: this.get('duration')
          }
        }
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.sendAction('onClose');
        if (_modernSchemas.default.includes(self.get('record.schema.slug'))) {
          self.get('routing').transitionTo('test.dataset', record);
        } else {
          self.get('routing').transitionTo('test.data', record);
        }
      }, function () {
        alert('failed!');
      });
    },
    deviceLabelCallback: function deviceLabelCallback(model) {
      return model.get('label');
    },


    actions: {
      confirm: function confirm() {
        //this.createDataSet();
        this.checkOverage();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      },
      processStripeToken: function processStripeToken(token) {
        this.processStripeToken(token);
      }
    }
  });
});