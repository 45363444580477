define('bc-frontend/components/tests/generate-data-dialog/component', ['exports', 'bc-frontend/data-set-schema/supports-sampling'], function (exports, _supportsSampling) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      Component = Ember.Component;
  exports.default = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),
    store: inject.service(),

    test: null, // injected as param
    clearExisting: false, // injected as param
    hasData: false, // injected as param
    targetSchema: null, // injected as param

    showSampling: Ember.computed('targetSchema', function () {
      return _supportsSampling.default[this.get('targetSchema')];
    }),

    sampleDuration: null,

    convertBulk: function convertBulk() {
      var self = this,
          testId = this.get('test.id'),
          ajax = this.get('ajax'),
          clearExisting = this.get('clearExisting'),
          hasData = this.get('hasData'),
          targetSchema = this.get('targetSchema'),
          showSampling = this.get('showSampling'),
          sampleDuration = this.get('sampleDuration'),
          requestUri = '/api/tests/' + testId + '/convert_bulk';

      var payload = {
        test: {
          target: targetSchema
        }
      };

      if (clearExisting || !hasData) {
        payload.test.clearExisting = true;
      }

      if (showSampling && sampleDuration) {
        if (sampleDuration < 1) {
          return;
        }

        payload.test.settings = {
          sample_duration: sampleDuration
        };
      }

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.sendAction('onClose');
        // self.get('routing').transitionTo('test.')
      });
    },


    actions: {
      confirm: function confirm() {
        this.convertBulk();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});