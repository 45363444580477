define('bc-frontend/components/collaborator-tile/change/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      confirm: function confirm() {
        this.sendAction('onChange');
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});