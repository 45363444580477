define('bc-frontend/test/data/controller', ['exports', 'bc-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    thumbnailUrl: Ember.computed('model.thumbnailUrl', 'thumbnailStamp', function () {
      var thumbnailUrl = this.get('model.thumbnailUrl'),
          modelId = this.get('model.id'),
          stamp = this.get('thumbnailStamp'),
          dataHost = _environment.default['apiHost'],
          token = this.get('session.session.authenticated.token');

      // // ** TODO: BEGIN HACKY SECTION
      // if (thumbnailUrl && this.get('inCalibrationMode') && this.get('lastRatio')) {
      //   let urlSections = thumbnailUrl.split('.');
      //   thumbnailUrl = urlSections[0] + '_cal.' + urlSections[1];
      // }
      // // ** END HACKY SECTION

      // if (thumbnailUrl && stamp) {
      //   let stampInt = stamp.valueOf();
      //   return `${dataHost}${thumbnailUrl}?stamp=${stampInt}`;
      //   // return `${thumbnailUrl}?stamp=${stampInt}`;
      // }

      if (thumbnailUrl && stamp) {
        var stampInt = stamp.valueOf();
        var requestObj = {
          'token': token
        };
        var q = btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(requestObj))));
        // return `${dataHost}/data/${modelId}_thumb${calSuffix}.jpg?stamp=${stampInt}`;
        return dataHost + '/api/dataSets/' + modelId + '/thumbnail?stamp=' + stampInt + '&q=' + q;
      }

      return null;
    }),

    thumbnailStamp: null,
    //elapsed: 0,

    elapsed: Ember.computed('model.last', 'model.start', function () {
      var elapsed = parseInt((this.get('model.last') - this.get('model.start')) / 1000);
      if (elapsed < 0) return 0;
      return elapsed;
    }),

    duration: Ember.computed('model.end', 'model.start', function () {
      return parseInt((this.get('model.end') - this.get('model.start')) / 1000);
    }),

    lastRatio: null,
    isSaving: false,

    isAborted: Ember.computed.equal('model.status', 'aborted'),
    hadError: Ember.computed.equal('model.status', 'error'),
    isCreated: Ember.computed.equal('model.status', 'created'),
    isCalibrated: Ember.computed.equal('model.status', 'calibrated'),
    isCollecting: Ember.computed.equal('model.status', 'collecting'),
    isCompleted: Ember.computed.equal('model.status', 'completed'),
    isAwaitingInput: Ember.computed.equal('model.status', 'meta'),

    isIdle: Ember.computed.equal('model.substate', 'idle'),
    isInitializing: Ember.computed.equal('model.substate', 'init'),
    isCalibratingError: Ember.computed.equal('model.substate', 'cal_search'),
    isCalibratingFound: Ember.computed.equal('model.substate', 'cal_found'),
    isClearing: Ember.computed.equal('model.substate', 'await_clear'),
    isCleared: Ember.computed.equal('model.substate', 'clearing'),
    isInserting: Ember.computed.equal('model.substate', 'await_insert'),
    isInserted: Ember.computed.equal('model.substate', 'inserting'),
    isRunning: Ember.computed.equal('model.substate', 'running'),

    inCalibrationMode: Ember.computed.or('isCalibratingError', 'isCalibratingFound', 'isCalibrated'),
    inMetaMode: Ember.computed.or('isAwaitingInput'),
    inInitializationMode: Ember.computed.or('isCreated', 'isIdle', 'isInitializing'),
    inClearingMode: Ember.computed.or('isClearing', 'isCleared'),
    inInsertingMode: Ember.computed.or('isInserting', 'isInserted'),
    inCollectionMode: Ember.computed.or('isCollecting'),
    inRunningMode: Ember.computed.or('isRunning'),

    shouldShowAbort: Ember.computed.or('isCreated', 'isCollecting'),
    shouldShowDownload: Ember.computed.and('model.canExport', 'isCompleted'),

    exportLink: Ember.computed('model', 'session.session.authenticated.token', function () {
      var token = this.get('session.session.authenticated.token'),
          dataSetId = this.get('model.id'),
          dataHost = _environment.default['apiHost'];

      var requestObj = {
        'token': token
      };
      var q = btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(requestObj))));

      return dataHost + '/api/dataSets/' + dataSetId + '/csv?q=' + q;
    }),

    initialize: function initialize() {
      this.set('thumbnailStamp', null);
      // this.set('elapsed', 0);
      this.set('lastRatio', null);
    },
    onModelUpdate: function onModelUpdate(data) {
      if (data.hasOwnProperty('status')) {
        if (data.status == 'completed') {
          // TODO: find a way to grab the next state, etc.
          this.onCollectionCompleted(data);
        } else if (data.status == 'converting') {
          this.onSavePending();
        }
      } else if (data.hasOwnProperty('substate')) {
        if (data.substate == 'cal_found') {
          this.onCalibrationUpdate(data);
        }
      }
    },
    onCalibrationUpdate: function onCalibrationUpdate(data) {
      this.set('lastRatio', JSON.parse(data.metaData).distance_ratio);
      this.set('thumbnailStamp', new Date());
    },
    onThumbnailUpdated: function onThumbnailUpdated(data) {
      // this.set('elapsed', data.elapsed);
      this.set('thumbnailStamp', new Date());
    },
    onCollectionCompleted: function onCollectionCompleted(data) {
      this.set('isSaving', false);

      if (data.status === 'completed') {
        if (data.hasOwnProperty('metaData') && JSON.parse(data.metaData).prompt_next) {
          this.set('showNextSubject', true);
        } else {
          this.transitionToRoute('test');
        }
      } else {
        this.set('showStatusDialog', true);
      }
    },
    onSavePending: function onSavePending() {
      this.set('isSaving', true);
    },
    finishCalibration: function finishCalibration() {
      var self = this,
          recordId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/calibrate';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.get('model').reload();
      }, function () {
        alert('failed!');
      });
    },
    confirmCleared: function confirmCleared() {
      var self = this,
          recordId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/cleared';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.get('model').reload();
      }, function () {
        alert('failed!');
      });
    },
    confirmInserted: function confirmInserted() {
      var self = this,
          recordId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/inserted';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.get('model').reload();
      }, function () {
        alert('failed!');
      });
    },


    actions: {
      useCalibration: function useCalibration() {
        this.finishCalibration();
      },
      confirmClearing: function confirmClearing() {
        this.confirmCleared();
      },
      confirmInserted: function confirmInserted() {
        this.confirmInserted();
      },
      timeUpdate: function timeUpdate(stamp) {
        console.log(stamp);
        this.set('model.last', stamp);
      }
    }
  });
});