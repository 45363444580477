define('bc-frontend/flash-support/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isSupported: Ember.computed(function () {
      /**
       * Tries to show browser's promt for enabling flash
       *
       * Chrome starting from 56 version and Edge from 15 are disabling flash
       * by default. To promt user to enable flash, they suggest to send user to
       * flash player download page. Then this browser will catch such request
       * and show a promt to user:
       * https://www.chromium.org/flash-roadmap#TOC-Developer-Recommendations
       * In this method we are forcing such promt by navigating user to adobe
       * site in iframe, instead of top window
       */
      function requestFlashPermission() {
        var iframe = document.createElement('iframe');
        iframe.src = 'https://get.adobe.com/flashplayer';
        iframe.sandbox = '';
        document.body.appendChild(iframe);
        document.body.removeChild(iframe);
      }

      var isNewEdge = (navigator.userAgent.match(/Edge\/(\d+)/) || [])[1] > 14;
      var isNewSafari = (navigator.userAgent.match(/OS X (\d+)/) || [])[1] > 9;
      var isNewChrome = (navigator.userAgent.match(/Chrom(e|ium)\/(\d+)/) || [])[2] > 56 && !/Mobile/i.test(navigator.userAgent);
      var canRequestPermission = isNewEdge || isNewSafari || isNewChrome;

      var hasFlash = null;

      try {
        var fObj = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
        hasFlash = !!fObj;
      } catch (e) {
        var mTypes = navigator.mimeTypes["application/x-shockwave-flash"];
        hasFlash = !!mTypes;
      }

      if (!hasFlash && canRequestPermission) {
        // in case, when flash is not available, try to prompt user to enable it
        requestFlashPermission();
        // Chrome requires user's click in order to allow iframe embeding
        $(window).one('click', requestFlashPermission);
      }

      return hasFlash;
    })
  });
});