define('bc-frontend/mixins/data-set-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // dsUpdates: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      // let dsUpdates = this.get('dsUpdates');
      // dsUpdates.activateDS(controller, model.get('id'));
      if (model && model.watchUpdates && controller.onModelUpdate) {
        model.watchUpdates(this, controller.onModelUpdate.bind(controller));
      }
    },
    deactivate: function deactivate() {
      var controller = this.get('controller'),
          model = this.get('controller.model');

      this._super();

      // let dsUpdates = this.get('dsUpdates');
      // dsUpdates.deactivateDS();
      if (model && model.watchUpdates && controller.onModelUpdate) {
        model.unwatchUpdates(this, controller.onModelUpdate.bind(controller));
      }
    }
  });
});