define('bc-frontend/components/dataset-detail/components/table/component', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajaxNoAuth: Ember.inject.service(),

    model: null,
    url: Ember.computed.reads('model.url'),

    dataRows: Ember.computed(function () {
      var ajax = this.get('ajaxNoAuth'),
          url = this.get('url');

      if (!url) {
        return Ember.A([]);
      }

      var ajaxPromise = ajax.request(url).then(function (result) {
        return result;
      }, function () {
        return Ember.A([]);
      });

      return _emberData.default.PromiseArray.create({
        promise: ajaxPromise
      });
    })
  });
});