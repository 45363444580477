define('bc-frontend/components/dataset-detail/components/manual-scoring-data/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    ajaxNoAuth: Ember.inject.service(),
    keyManager: Ember.inject.service(),

    model: null,
    uri: Ember.computed.reads('model.uri'),

    // have to do wonky computed workaround to initialize model to an object
    // or else all instances of the editor component will share the same singleton
    configurationModel: Ember.computed(function () {
      return Ember.Object.create({
        subjects: [],
        verbGroup: [],
        verbs: []
      });
    }),

    src: Ember.computed('video', function () {
      return {
        src: this.get('video'),
        type: 'video/mp4'
      };
    }),

    playerSetup: {
      controls: true
    },

    videoStart: Ember.computed('model.meta', function () {
      var recorded = (0, _moment.default)(this.get('model.metaData.recordTs')),
          cleared = (0, _moment.default)(this.get('model.metaData.insertedTs'));

      return cleared.diff(recorded, 'seconds');
    }),

    dataModified: false,

    dataModifiedObserver: Ember.observer('dataModified', function () {
      this.sendAction('onModifiedChanged', this.get('dataModified'));
    }),

    scoringModel: Ember.computed(function () {
      return [];
    }),

    scoringSort: ['time'],
    sortedScoringModel: Ember.computed.sort('scoringModel', 'scoringSort'),

    recordTS: Ember.computed('model.metaData.insertedTs', function () {
      var recordTS = this.get('model.metaData.insertedTs');
      return (0, _moment.default)(recordTS);
    }),
    timePosition: Ember.computed.reads('recordTS'),

    stateHistory: Ember.computed('sortedScoringModel', 'recordTS', 'endTS', function () {
      var lastState = this.get('configurationModel.verbs').map(function (verb) {
        var verbCopy = Object.assign({}, verb);
        verbCopy.state = verb.groupDefault;
        return verbCopy;
      });
      var lastTS = this.get('recordTS');
      var lastChange = null;
      var endTS = this.get('endTS');

      if (!lastTS || !endTS) {
        return [];
      }

      var states = this.get('sortedScoringModel').map(function (change) {
        var state = {
          start: lastTS,
          end: change.time,
          state: lastState.map(function (verb) {
            return verb.state;
          }),
          change: lastChange
        };

        lastTS = change.time;

        // TODO: update lastState and clear other group members
        var groupId = null;
        lastState.forEach(function (verb) {
          if (verb.id === change.verb) {
            verb.state = change.verbValue;
            groupId = verb.groupId;
          }
        });
        lastState.forEach(function (verb) {
          if (verb.id !== change.verb && (groupId && verb.groupId === groupId || verb.instantaneous)) {
            verb.state = false;
          }
        });
        lastChange = change;

        return state;
      });

      states.pushObject({
        start: lastTS,
        end: endTS,
        state: lastState.map(function (verb) {
          return verb.state;
        }),
        change: lastChange
      });

      return states;
    }),

    verbState: function verbState(verb) {
      var verbIndex = this.get('configurationModel.verbs').indexOf(verb),
          position = this.get('timePosition');

      var state = this.get('stateHistory').reduce(function (value, state) {
        if (value) return value;
        if (state.start <= position && state.end > position) return state;
        return null;
      }, null);

      return state.state[verbIndex];
    },


    _setup: function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        _this.loadConfiguration();
        _this.loadData();
        _this.setupKeyBindings();
      });
    }.on('didInsertElement'),

    setupKeyBindings: function setupKeyBindings() {
      this.get('keyManager').register({
        keys: ['backspace'],
        name: 'manual-scoring-backspace',
        downCallback: Ember.run.bind(this, function () {
          this.send('deleteSegment');
        }),
        priority: 10
      });
      this.get('keyManager').register({
        keys: ['delete'],
        name: 'manual-scoring-delete',
        downCallback: Ember.run.bind(this, function () {
          this.send('deleteSegment');
        }),
        priority: 10
      });
    },
    setupVerbKeyBindings: function setupVerbKeyBindings() {
      var _this2 = this;

      var keyManager = this.get('keyManager');

      this.get('configurationModel.verbs').forEach(function (verb) {
        if (verb.key) {
          var key = verb.key;


          keyManager.register({
            keys: [key],
            name: 'manual-scoring-' + key,
            downCallback: Ember.run.bind(_this2, function (verb) {
              this.send('toggleVerb', verb);
            }, verb),
            priority: 10
          });
        }
      });
    },
    loadConfiguration: function loadConfiguration() {
      var self = this,
          ajax = this.get('ajaxNoAuth'),
          url = this.get('configuration');

      if (url) {
        ajax.request(url).then(function (result) {
          var groupMap = {};

          var model = Ember.Object.create({
            subjects: [],
            verbGroup: result.verbGroup.map(function (group) {
              return Ember.Object.create(group);
            }),
            verbs: result.verbs.map(function (verb) {
              return Ember.Object.create(verb);
            })
          });

          model.verbGroup.forEach(function (group) {
            groupMap[group.id] = group;
          });
          model.verbs.forEach(function (verb) {
            verb.set('group', groupMap[verb.groupId]);
          });

          self.set('configurationModel', model);

          self.setupVerbKeyBindings();
        });
      }
    },
    loadData: function loadData() {
      var self = this,
          ajax = this.get('ajaxNoAuth'),
          url = this.get('model.url');

      if (url) {
        ajax.request(url).then(function (result) {
          var scoringModel = result.map(function (change) {
            return Ember.Object.create({
              time: (0, _moment.default)(change.time),
              verb: change.verb,
              verbValue: change.verbValue,
              selected: false
            });
          });

          self.set('scoringModel', scoringModel);
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.removeKeyBindings();
    },
    removeKeyBindings: function removeKeyBindings() {
      var keyManager = this.get('keyManager');

      keyManager.deregister({ name: 'manual-scoring-backspace' });
      keyManager.deregister({ name: 'manual-scoring-delete' });

      this.get('configurationModel.verbs').forEach(function (verb) {
        if (verb.key) {
          var key = verb.key;

          keyManager.deregister({ name: 'manual-scoring-' + key });
        }
      });
    },
    exportData: function exportData() {
      return this.get('scoringModel').map(function (change) {
        return {
          time: change.time,
          verb: change.verb,
          verbValue: change.verbValue
        };
      });
    },
    saveData: function saveData() {
      var self = this,
          data = this.exportData(),
          dsId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + dsId + '/data';

      var payload = {
        data: data
      };

      this.set('isSaving', true);

      ajax.request(requestUri, {
        method: 'put',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.set('dataModified', false);
        self.set('isSaving', false);
      }, function () {
        alert('Failed to save data. Please try again momentarily.');
        self.set('isSaving', false);
      });
    },
    appendVerb: function appendVerb(verb) {
      var timePosition = this.get('timePosition');
      var scoringModel = this.get('scoringModel');
      var lastState = this.verbState(verb);

      // If this verb is already active and we're in a group,
      // no need to do anything.
      if (lastState && verb.groupId) {
        return;
      }

      scoringModel.pushObject(Ember.Object.create({
        time: timePosition,
        verb: verb.id,
        verbValue: verb.instantaneous || !lastState,
        selected: false
      }));

      this.set('dataModified', true);
    },
    selectSegment: function selectSegment(segment) {
      this.get('scoringModel').forEach(function (change) {
        change.set('selected', false);
      });

      if (segment.change) {
        segment.change.set('selected', true);
      }
    },
    deleteSegment: function deleteSegment() {
      var _this3 = this;

      var changesToDelete = this.get('scoringModel').filter(function (change) {
        return change.selected;
      });

      changesToDelete.forEach(function (change) {
        _this3.get('scoringModel').removeObject(change);
      });

      this.set('dataModified', true);
    },


    actions: {
      setPosition: function setPosition(position) {
        var recordTS = this.get('recordTS');
        this.set('timePosition', (0, _moment.default)(recordTS).add(position, 'seconds'));
      },
      setDuration: function setDuration(duration) {
        var recordTS = this.get('recordTS');
        this.set('endTS', (0, _moment.default)(recordTS).add(duration, 'seconds'));
      },
      toggleVerb: function toggleVerb(verb) {
        this.appendVerb(verb);
      },
      selectSegment: function selectSegment(segment) {
        this.selectSegment(segment);
      },
      deleteSegment: function deleteSegment() {
        this.deleteSegment();
      },
      saveData: function saveData() {
        this.saveData();
      },
      playerReady: function playerReady(player, component) {
        component.sendActionOnPlayerEvent(player, 'timeupdate');
        component.sendActionOnPlayerEvent(player, 'durationchange');
        component.sendActionOnPlayerEvent(player, 'loadeddata');
      },
      playerTimeUpdate: function playerTimeUpdate(player) {
        var duration = player.duration();
        if (duration && !this.get('endTS')) {
          this.send('setDuration', duration);
        }
        this.send('setPosition', player.currentTime());
      },
      playerDurationChange: function playerDurationChange(player) {
        this.send('setDuration', player.duration());
      },
      playerLoadedData: function playerLoadedData(player) {
        player.hasStarted(true);
      }
    }
  });
});