define('bc-frontend/components/tests/confirm-abort-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      Component = Ember.Component;
  exports.default = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),
    store: inject.service(),

    abortDataSet: function abortDataSet() {
      var self = this,
          recordId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/abort';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.sendAction('onClose');
      }, function () {
        alert('failed!');
      });
    },


    actions: {
      confirm: function confirm() {
        this.abortDataSet();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});