define('bc-frontend/ajax/service', ['exports', 'ember-ajax/services/ajax', 'bc-frontend/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: _environment.default['apiHost'],
    headers: Ember.computed('session.session.authenticated.token', {
      get: function get() {
        var headers = {};
        this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        return headers;
      }
    })
  });
});