define('bc-frontend/data-set/ability', ['exports', 'ember-can'], function (exports, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCan.Ability.extend({
    test: Ember.computed.alias('model.test'),

    canManage: Ember.computed('test.myPermissions', function () {
      var permissions = this.get('test.myPermissions');
      return permissions && permissions.indexOf('manage_test') >= 0;
    }),
    canChange: Ember.computed('test.myPermissions', function () {
      var permissions = this.get('test.myPermissions');
      return permissions && permissions.indexOf('change_test') >= 0;
    }),
    canRead: Ember.computed('test.myPermissions', function () {
      var permissions = this.get('test.myPermissions');
      return permissions && permissions.indexOf('read_test') >= 0;
    }),
    canDelete: Ember.computed('test.myPermissions', function () {
      var permissions = this.get('test.myPermissions');
      return permissions && permissions.indexOf('delete_test') >= 0;
    })
  });
});