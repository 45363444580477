define('bc-frontend/components/dataset-detail/components/video-live-preview/component', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tries: 0,
    classNames: ['video-live-preview'],

    onTimeUpdate: null, // should be passed in by parent

    substate: Ember.computed.alias('model.substate'),
    metaData: Ember.computed.alias('model.metaData'),

    showCalibrationOverlay: Ember.computed('substate', function () {
      var substate = this.get('substate');

      return substate.toUpperCase() == 'CAL_FOUND';
    }),

    calibrationCorners: Ember.computed('substate', 'metaData', function () {
      var substate = this.get('substate'),
          metaData = this.get('metaData');

      if (substate.toUpperCase() != 'CAL_FOUND' || !metaData) {
        return Ember.A([]);
      }

      if (typeof metaData == "string") {
        metaData = JSON.parse(metaData);
      }

      return Ember.A(metaData.corners);
    }),

    hlsPromise: Ember.computed('model', 'tries', function () {
      var _this = this,
          dsId = this.get('model.id'),
          tries = this.get('tries'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + dsId + '/get_realtime_url';

      var promise = ajax.request(requestUri, {
        method: 'get',
        contentType: 'application/json'
      }).then(function (data) {
        return data.dataSet.url;
      }).catch(function () {
        // Sometimes the stream won't be quite ready to offer up fragments when we
        // request it. Retry up to 3 times to grab the stream.
        if (tries < 3) {
          console.warn("Failed to acquire stream. Retrying...");
          _this.set('tries', tries + 1);
        }
      });

      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    hlsUrl: Ember.computed.alias('hlsPromise.content'),
    hlsPending: Ember.computed.alias('hlsPromise.isPending')
  });
});