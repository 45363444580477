define('bc-frontend/components/ivy-videojs/component', ['exports', 'ivy-videojs/components/ivy-videojs', 'videojs'], function (exports, _ivyVideojs, _videojs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Default options for the plugin.
  var defaults = {};

  /**
   * Function to invoke when the player is ready.
   *
   * This is a great place for your plugin to initialize itself. When this
   * function is called, the player will have its DOM and child components
   * in place.
   *
   * @function onPlayerReady
   * @param    {Player} player
   *           A Video.js player.
   * @param    {Object} [options={}]
   *           An object of options left to the plugin author to define.
   */
  var onPlayerReady = function onPlayerReady(player) {
    player.on('timeupdate', function () {
      var curr = this.currentTime();

      if (curr < 0) {
        this.currentTime(0);
        this.play();
      }
      if (this._offsetEnd > 0 && curr > this._offsetEnd - this._offsetStart) {
        this.pause();
        if (!this._restartBeginning) {
          this.currentTime(this._offsetEnd - this._offsetStart);
        } else {
          this.trigger('loadstart');
          this.currentTime(0);
        }
      }
    });
  };

  /**
   * A video.js plugin.
   *
   * In the plugin function, the value of `this` is a video.js `Player`
   * instance. You cannot rely on the player being in a "ready" state here,
   * depending on how the plugin is invoked. This may or may not be important
   * to you; if not, remove the wait for "ready"!
   *
   * @function offset
   * @param    {Object} [options={}]
   *           An object of options left to the plugin author to define.
   */
  var offset = function offset(options) {
    var _this = this;

    options = options || {};
    var Player = this.constructor;

    this._offsetStart = options.start || 0;
    this._offsetEnd = options.end || 0;
    this._restartBeginning = options.restart_beginning || false;

    if (!Player.__super__ || !Player.__super__.__offsetInit) {
      Player.__super__ = {
        __offsetInit: true,
        duration: Player.prototype.duration,
        currentTime: Player.prototype.currentTime,
        bufferedPercent: Player.prototype.bufferedPercent,
        remainingTime: Player.prototype.remainingTime
      };

      Player.prototype.duration = function () {
        if (this._offsetEnd > 0) {
          return this._offsetEnd - this._offsetStart;
        }
        return Player.__super__.duration.apply(this, arguments) - this._offsetStart;
      };

      Player.prototype.currentTime = function (seconds) {
        if (seconds !== undefined) {
          return Player.__super__.currentTime.call(this, seconds + this._offsetStart) - this._offsetStart;
        }
        return Player.__super__.currentTime.apply(this, arguments) - this._offsetStart;
      };

      Player.prototype.remainingTime = function () {
        var curr = this.currentTime();

        if (curr < this._offsetStart) {
          curr = 0;
        }
        return this.duration() - curr;
      };

      Player.prototype.startOffset = function () {
        return this._offsetStart;
      };

      Player.prototype.endOffset = function () {
        if (this._offsetEnd > 0) {
          return this._offsetEnd;
        }
        return this.duration();
      };
    }

    this.ready(function () {
      onPlayerReady(_this, _videojs.default.mergeOptions(defaults, options));
    });
  };

  exports.default = _ivyVideojs.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      // Cross-compatibility for Video.js 5 and 6.
      var registerPlugin = _videojs.default.registerPlugin || _videojs.default.plugin;

      // Register the plugin with video.js.
      registerPlugin('offset', offset);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      //debugger;
    }
  });
});