define('bc-frontend/components/dataset-detail/components/video-live-preview/calibration-overlay/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: null,
    corners: null // SHOULD BE INJECTED BY PARENT
  });
});