define('bc-frontend/components/tests/capture-calibration-dialog/component', ['exports', 'bc-frontend/data-set/modern-schemas'], function (exports, _modernSchemas) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject,
      Component = Ember.Component;
  exports.default = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),
    store: inject.service(),

    devices: computed('schema', function () {
      var schema = this.get('schema');
      return this.get('store').query('dataSet', { realtime: true, schema: 4 });
    }),

    device: null,

    createDataSet: function createDataSet() {
      var self = this,
          deviceId = this.get('device.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + deviceId + '/create_observer_dataset';

      var payload = {
        dataSet: {
          test: self.get('test.id'),
          subject: 'CALIBRATION',
          schema: 1 // calibration
        }
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function (recordJson) {
        self.captureDataSet(self.get('store').pushPayload(recordJson)[0]);
      }, function () {
        alert('failed!');
      });
    },
    captureDataSet: function captureDataSet(record) {
      var self = this,
          recordId = record.get('id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/capture';

      var payload = {
        dataSet: {
          settings: {
            duration: 0
          }
        }
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.sendAction('onClose');
        self.get('routing').transitionTo('test.data', record);
      }, function () {
        alert('failed!');
      });
    },
    deviceLabelCallback: function deviceLabelCallback(model) {
      return model.get('label');
    },


    actions: {
      confirm: function confirm() {
        this.createDataSet();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});