define('bc-frontend/components/schema-tile/analysis/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: null,

    actions: {
      generateModalClosed: function generateModalClosed() {
        this.set('showGenerateAnalysis', false);
      },
      regenerateModalClosed: function regenerateModalClosed() {
        this.set('showRegenerateAnalysis', false);
      }
    }
  });
});