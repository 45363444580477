define('bc-frontend/activate/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    queryParams: {
      token: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var user_id = params.user_id,
          token = params.token;

      var ajax = this.get('ajax'),
          payload = {
        confirm: {
          id: user_id,
          token: token
        }
      };

      // return this.get('store').findRecord('user', params.user_id);
      return ajax.request('/api/auth/confirm', {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        return true;
      }, function () {
        // do nothing
      });
    }
  });
});