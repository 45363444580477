define('bc-frontend/mixins/device-preview-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEVICE_MESSAGES = Ember.A(['data_received']);

  exports.default = Ember.Mixin.create({
    pusher: Ember.inject.service(),
    watchedChannel: null,

    previewData: Ember.computed.alias('data.device'),
    channelUri: Ember.computed.alias('previewData.uri'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this,
          pusher = this.get('pusher.pusher'),
          channelUri = this.get('channelUri');

      // subscribe to user channel
      var channel = pusher.subscribe(channelUri);
      this.set('watchedChannel', channel);

      DEVICE_MESSAGES.forEach(function (msg) {
        channel.bind(msg, self.handleMessage.bind(self, msg));
      });
    },
    handleMessage: function handleMessage(message, data) {
      this.dataReceived(data.payload);
    },
    dataReceived: function dataReceived() /*payload*/{
      // Not implemented
      console.log('Data received but no handler implemented.');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var pusher = this.get('pusher.pusher'),
          channelUri = this.get('channelUri');

      pusher.unsubscribe(channelUri);
      this.set('watchedChannel', null);
    }
  });
});