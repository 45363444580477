define('bc-frontend/services/intercom', ['exports', 'ember-intercom-io/services/intercom', 'bc-frontend/config/environment'], function (exports, _intercom, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      getWithDefault = _Ember.getWithDefault;
  exports.default = _intercom.default.extend({
    config: getWithDefault(_environment.default, 'intercom', {})
  });
});