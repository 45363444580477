define('bc-frontend/components/dataset-detail/layouts/centroid/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    videoPath: Ember.computed.alias('dataSet.realSiblings.video-path'),
    tracePath: Ember.computed.alias('dataSet.realSiblings.trace-path')
  });
});