define('bc-frontend/components/schema-tile/zone-sequence/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: null,
    ajax: Ember.inject.service(),

    actions: {
      convert: function convert(clearExisting) {
        var testId = this.get('test.id'),
            ajax = this.get('ajax'),
            requestUri = '/api/tests/' + testId + '/convert_bulk';

        var payload = {
          test: {
            target: this.schema.slug,
            clearExisting: clearExisting
          }
        };

        ajax.request(requestUri, {
          method: 'post',
          data: JSON.stringify(payload),
          contentType: 'application/json'
        }).then(function () {});
      }
    }
  });
});