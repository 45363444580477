define('bc-frontend/components/dataset-detail/components/image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: null,
    classNames: ['dataset-image'],
    uri: Ember.computed.reads('model.uri')
  });
});