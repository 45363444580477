define('bc-frontend/utils/key-codes', ['exports', 'ember-key-manager/utils/key-codes'], function (exports, _keyCodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _keyCodes.default;
    }
  });
});