define('bc-frontend/components/device-preview/bc-test/component', ['exports', 'bc-frontend/mixins/device-preview-mixin'], function (exports, _devicePreviewMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_devicePreviewMixin.default, {
    isWaiting: true,
    lastSample: {},

    dataReceived: function dataReceived(payload) {
      this.set('isWaiting', false);
      this.set('lastSample', payload);
    }
  });
});