define('bc-frontend/test/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    created: (0, _attr.default)('date'),
    deletedAt: (0, _attr.default)('date'),
    updated: (0, _attr.default)('date'),
    user: (0, _relationships.belongsTo)('user'),

    name: (0, _attr.default)('string'),
    label: (0, _attr.default)('string'),
    subjectCount: (0, _attr.default)('number'),
    hasCollaborators: (0, _attr.default)('boolean'),
    myPermissions: (0, _attr.default)(),

    schema: (0, _relationships.belongsTo)('testSchema'),
    liveTest: (0, _attr.default)('boolean'),
    supportsMultichamber: (0, _attr.default)('boolean')
  });
});