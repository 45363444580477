define('bc-frontend/components/ivy-videojs-player/component', ['exports', 'ivy-videojs/components/ivy-videojs-player', 'videojs'], function (exports, _ivyVideojsPlayer, _videojs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ivyVideojsPlayer.default.extend({
    offsetStart: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.updateOffset();
    },


    onOffsetChange: Ember.observer('offsetStart', function () {
      this.updateOffset();
    }),

    updateOffset: function updateOffset() {
      var start = this.get('offsetStart');

      if (this.get('offsetStart')) {
        (0, _videojs.default)(this.element).offset({ start: start });
      }
    }
  });
});