define('bc-frontend/test/manual-data/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({
    ajax: inject.service(),
    session: inject.service(),

    testController: inject.controller('test'),
    test: computed.alias('testController.model'),

    dataSets: computed.alias('model'),
    dataSetsCount: computed.alias('dataSets.length'),
    hasData: computed.gte('dataSetsCount', 1),

    showCollectButton: computed.alias('model.meta.canCollect'),

    actions: {
      generate: function generate(clearExisting) {
        var self = this,
            testId = this.get('test.id'),
            ajax = this.get('ajax'),
            requestUri = '/api/tests/' + testId + '/convert_bulk';

        var payload = {
          test: {
            source: 'video',
            target: 'manual-scoring-data',
            clearExisting: clearExisting
          }
        };

        ajax.request(requestUri, {
          method: 'post',
          data: JSON.stringify(payload),
          contentType: 'application/json'
        }).then(function () {
          self.get('target.router').refresh();
        });
      }
    }
  });
});