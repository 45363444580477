define('bc-frontend/login/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    activate: function activate() {
      this.controllerFor('login').set('isPending', false);
    }
  });
});