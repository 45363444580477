define('bc-frontend/components/device-preview/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    dataSets: Ember.computed('device.id', function () {
      return this.get('store').query('dataSet', {
        device: this.get('device.id')
      });
    }),

    isLoading: Ember.computed.alias('dataSets.isPending')
  });
});