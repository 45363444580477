define('bc-frontend/helpers/transition-to', ['exports', 'ember-transition-helper/helpers/transition-to'], function (exports, _transitionTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _transitionTo.default;
    }
  });
  Object.defineProperty(exports, 'transitionTo', {
    enumerable: true,
    get: function () {
      return _transitionTo.transitionTo;
    }
  });
});