define('bc-frontend/test/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({
    testController: inject.controller('test'),
    test: computed.alias('testController.model')
  });
});