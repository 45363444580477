define('bc-frontend/components/tests/upload-data-set-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject,
      Component = Ember.Component;
  exports.default = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),
    store: inject.service(),
    userSession: inject.service(),

    showUpgradePrompt: false,
    uploads: Ember.computed(function () {
      return Ember.A([
        // {fileName: 'weee.mp4', progress: 50},
        // {fileName: 'test.mp4', progress: 25},
        // {fileName: 'test2.mp4', progress: 35},
        // {fileName: 'test3.mp4', progress: 95},
      ]);
    }),
    inProgress: Ember.computed.filterBy('uploads', 'active', true),

    checkOverage: function checkOverage(duration) {
      var self = this,
          ajax = this.get('ajax'),
          userId = this.get('userSession.userId');

      return ajax.request('/api/users/' + userId + '/get_overage_cost?time=' + duration).then(function (response) {
        var upgradeNeeded = response.user.upgradeNeeded;

        if (upgradeNeeded) {
          self.set('showUpgradePrompt', true);
          return false;
        } else {
          return true;
        }
      }, function (error) {
        if (error.errors[0].status === "400") {
          self.set('showUpgradePrompt', true);
          return false;
        }
        alert("We were unable to communicate with the server. Please try again momentarily.");
        return false;
      });
    },
    processStripeToken: function processStripeToken(token) {
      var self = this,
          ajax = this.get('ajax'),
          userId = this.get('userSession.userId'),
          url = '/api/users/' + userId + '/create_card',
          payload = {
        user: {
          card: token.id
        }
      };

      ajax.request(url, {
        method: 'post',
        contentType: 'application/json',
        data: JSON.stringify(payload)
      }).then(function () {
        self.set('showUpgradePrompt', false);
      }, function () {
        alert("Failed to subscribe. Please try again shortly.");
      });
    },


    actions: {
      confirm: function confirm() {
        //this.createDataSet();
        this.checkOverage();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      },
      processStripeToken: function processStripeToken(token) {
        this.processStripeToken(token);
      },
      uploadsComplete: function uploadsComplete() {
        this.sendAction('onUploaded');
        this.sendAction('onClose');
      }
    }
  });
});