define('bc-frontend/test/photobeam-activity/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'bc-frontend/mixins/data-set-route-mixin'], function (exports, _authenticatedRouteMixin, _dataSetRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _dataSetRouteMixin.default, {
    model: function model(params) {
      return this.get('store').query('dataSet', {
        test: this.modelFor('test').get('id'),
        schema: params.schema_id
      });
    }
  });
});