define('bc-frontend/components/video-calibration/auto/component', ['exports', 'bc-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    ajax: inject.service(),
    store: inject.service(),

    // supplied via param
    test: null,
    model: null,

    latestCalibration: computed.alias('model.firstObject'),
    dataSetCount: computed.alias('model.length'),
    hasData: computed.gte('dataSetCount', 1),

    showCollectButton: computed.not('hasData'),

    thumbnailUrl: Ember.computed('latestCalibration', 'thumbnailStamp', function () {
      var model = this.get('latestCalibration'),
          modelId = model.get('id'),
          stamp = this.get('thumbnailStamp'),

      // calSuffix = '',
      dataHost = _environment.default['apiHost'];

      // if (this.get('inCalibrationMode') && this.get('lastRatio')) {
      //   calSuffix = '_cal';
      // }

      if (model && stamp) {
        var stampInt = stamp.valueOf();
        // return `${dataHost}/data/${modelId}_thumb${calSuffix}.jpg?stamp=${stampInt}`;
        return dataHost + '/api/' + modelId + '/thumbnail?stamp=' + stampInt;
      }

      return null;
    }),

    thumbnailStamp: null,
    lastRatio: null,

    isAborted: Ember.computed.equal('latestCalibration.status', 'aborted'),
    hadError: Ember.computed.equal('latestCalibration.status', 'error'),
    isCreated: Ember.computed.equal('latestCalibration.status', 'created'),
    isCalibrating: Ember.computed.equal('latestCalibration.status', 'calibrating'),
    isCalibrated: Ember.computed.equal('latestCalibration.status', 'calibrated'),
    isCompleted: Ember.computed.equal('latestCalibration.status', 'completed'),

    inCalibrationMode: Ember.computed.or('isCalibrating', 'isCalibrated'),

    shouldShowAbort: Ember.computed.or('isCreated', 'isCalibrating'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initialize();
    },
    initialize: function initialize() {
      this.set('thumbnailStamp', null);
      this.set('lastRatio', null);
    },
    onCalibrationUpdate: function onCalibrationUpdate(data) {
      this.set('lastRatio', data.ratio);
      this.set('thumbnailStamp', new Date());
    },
    onSavePending: function onSavePending() {
      this.set('isSaving', true);
    },
    finishCalibration: function finishCalibration() {
      var self = this,
          recordId = this.get('latestCalibration.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/calibrate';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.get('latestCalibration').reload();
      }, function () {
        alert('failed!');
      });
    },


    actions: {
      useCalibration: function useCalibration() {
        this.finishCalibration();
      },
      recalibrate: function recalibrate() {
        this.sendAction('onAutoCalibrate');
      }
    }
  });
});