define('bc-frontend/components/change-password-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      Component = Ember.Component;
  exports.default = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),

    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',

    errorText: '',

    isPending: false,

    changePassword: function changePassword() {
      var self = this,
          ajax = this.get('ajax'),
          oldPassword = this.get('oldPassword'),
          newPassword = this.get('newPassword'),
          payload = {
        auth: {
          oldPassword: oldPassword,
          newPassword: newPassword
        }
      };

      this.set('isPending', true);
      ajax.request('/api/auth/change_password', {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.sendAction('onClose');
      }, function () {
        self.set('errorText', 'Please check your old password and try again.');
        self.set('isPending', false);
      });
    },


    isPopulated: Ember.computed.and('oldPassword', 'newPassword', 'confirmNewPassword'),
    isMatched: Ember.computed('newPassword', 'confirmNewPassword', function () {
      return this.get('newPassword') === this.get('confirmNewPassword');
    }),
    isValid: Ember.computed.and('isPopulated', 'isMatched'),

    notMatched: Ember.computed.not('isMatched'),
    showMismatchError: Ember.computed.and('isPopulated', 'notMatched'),

    notValid: Ember.computed.not('isValid'),
    notPending: Ember.computed.not('isPending'),

    disableSubmission: Ember.computed.or('notValid', 'isPending'),
    showError: Ember.computed.and('errorText', 'notPending'),

    actions: {
      confirm: function confirm() {
        this.changePassword();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});