define('bc-frontend/application/adapter', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'bc-frontend/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    namespace: 'api',
    coalesceFindRequests: true,
    plugin: [],
    authorizer: 'authorizer:token',
    host: _environment.default['apiHost']
  });
});