define('bc-frontend/user/model', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    email: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),

    displayName: Ember.computed('email', 'firstName', 'lastName', function () {
      var email = this.get('email'),
          first = this.get('firstName'),
          last = this.get('lastName');

      if (first) {
        return first + ' ' + last;
      }

      return email;
    }),

    numDevices: (0, _attr.default)('number')
  });
});