define('bc-frontend/activatepw/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),

    setPassword: function setPassword() {
      var self = this,
          _get = this.get('model'),
          user_id = _get.user_id,
          token = _get.token,
          password = this.get('password');


      var ajax = this.get('ajax'),
          payload = {
        confirm: {
          id: user_id,
          token: token,
          password: password
        }
      };

      this.set('isPending', true);

      // return this.get('store').findRecord('user', params.user_id);
      ajax.request('/api/auth/confirm', {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.set('submitResponse', true);
      }, function () {
        self.set('submitResponse', false);
      }).finally(function () {
        self.set('isSubmitted', true);
      });
    },


    actions: {
      setPassword: function setPassword() {
        this.setPassword();
      }
    }
  });
});