define('bc-frontend/user-session/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intercom: Ember.inject.service(),

    userId: Ember.computed.alias('session.data.authenticated.userId'),

    user: Ember.computed('session.isAuthenticated', function () {
      var userId = this.get('userId');
      if (userId) {
        return this.get('store').findRecord('user', userId);
      }
      return null;
    }),

    userChanged: Ember.observer('user.isFulfilled', function () {
      var user = this.get('user'),
          intercom = this.get('intercom');

      if (user && user.get('isFulfilled')) {
        var firstName = user.get('firstName'),
            lastName = user.get('lastName');

        intercom.set('user.email', user.get('email'));
        intercom.set('user.name', firstName + ' ' + lastName);
        intercom.start();

        // This is an example script - don't forget to change it!
        FS.identify(user.get('email'), {
          displayName: firstName + ' ' + lastName,
          email: user.get('email')
        });
      }
    }).on('init')
  });
});