define('bc-frontend/components/device-preview/sandiegoinstruments-activitychamber/component', ['exports', 'bc-frontend/mixins/device-preview-mixin', 'npm:base64-js'], function (exports, _devicePreviewMixin, _npmBase64Js) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAP_MASK = [{
    axis: 'x',
    beams: [[3, 0x01], [2, 0x01], [1, 0x01], [0, 0x01]]
  }, {
    axis: 'y',
    beams: [[3, 0x02], [2, 0x02], [1, 0x02], [0, 0x02], [3, 0x04], [2, 0x04], [1, 0x04], [0, 0x04]]
  }, {
    axis: 'z',
    beams: [[31, 0x01], [30, 0x01], [29, 0x01], [28, 0x01], [31, 0x02], [30, 0x02], [29, 0x02], [28, 0x02]]
  }];

  exports.default = Ember.Component.extend(_devicePreviewMixin.default, {
    isWaiting: true,
    lastSample: {},

    // isWaiting: false,
    // lastSample: [
    //   [true, false, false, false, true, false, false, false, true, false, false, false, true, false, false, false],
    //   [true, false, false, false, true, false, false, false, true, false, false, false, true, false, false, false],
    //   [true, false, false, false, true, false, false, false, true, false, false, false, true, false, false, false],
    // ],
    mask: MAP_MASK,

    dataReceived: function dataReceived(payload) {
      var decoded = _npmBase64Js.default.toByteArray(payload);

      var mapped = MAP_MASK.map(function (axis) {
        return axis.beams.map(function (beam) {
          return !!(decoded[beam[0]] & beam[1]);
        });
      });

      //debugger;

      this.set('isWaiting', false);
      this.set('lastSample', mapped);
    }
  });
});