define('bc-frontend/components/dataset-detail/components/video-live-preview/video-element/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        hls: null,
        video: null,
        showPlayOverlay: false,

        didRender: function didRender() {
            var _this = this;

            var url = this.get('url'),
                video = document.getElementById('video'),
                self = this;

            this.set('video', video);

            if (Hls.isSupported()) {
                var hls = new Hls();
                this.set('hls', hls);
                hls.loadSource(url);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    video.muted = true;
                    var playpromise = video.play();
                    if (playpromise !== undefined) {
                        playpromise.then(function () {
                            video.currentTime = video.duration - 1;
                        }).catch(function (e) {
                            debugger;
                            //self.set('showPlayOverlay', true);
                        });
                    }
                });
                hls.on(Hls.Events.FRAG_CHANGED, function (event, data) {
                    console.log("Raw program stamp " + data.frag.endProgramDateTime);
                    var stamp = new Date(data.frag.endProgramDateTime);
                    _this.sendAction('onTimeUpdate', stamp);
                });
            }
            // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
            // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
            // This is using the built-in support of the plain video element, without using hls.js.
            // Note: it would be more normal to wait on the 'canplay' event below however on Safari (where you are most likely to find built-in HLS support) the video.src URL must be on the user-driven
            // white-list before a 'canplay' event will be emitted; the last video event that can be reliably listened-for when the URL is not on the white-list is 'loadedmetadata'.
            else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.src = url;
                    video.addEventListener('loadedmetadata', function () {
                        video.muted = true;
                        var playpromise = video.play();
                        if (playpromise !== undefined) {
                            playpromise.then(function () {
                                video.currentTime = video.duration - 1;
                            }).catch(function (e) {
                                debugger;
                            });
                        }
                    });
                }

            this.setVisibilityHandlers();
        },
        willDestroyElement: function willDestroyElement() {
            this.removeVisibilityHandlers();
            this.get('hls').destroy();
        },
        setVisibilityHandlers: function setVisibilityHandlers() {
            // Set the name of the hidden property and the change event for visibility
            var hiddenAttr, visibilityChangeEvent;
            if (typeof document.hidden !== "undefined") {
                // Opera 12.10 and Firefox 18 and later support 
                hiddenAttr = "hidden";
                visibilityChangeEvent = "visibilitychange";
            } else if (typeof document.msHidden !== "undefined") {
                hiddenAttr = "msHidden";
                visibilityChangeEvent = "msvisibilitychange";
            } else if (typeof document.webkitHidden !== "undefined") {
                hiddenAttr = "webkitHidden";
                visibilityChangeEvent = "webkitvisibilitychange";
            }
            this.setProperties({ hiddenAttr: hiddenAttr, visibilityChangeEvent: visibilityChangeEvent });
            if (typeof document.addEventListener === "undefined" || hiddenAttr === undefined) {
                console.warn("Won't be able to force video to latest frame if user leaves/returns to tab; timestamp may render incorrectly.");
            } else {
                // Handle page visibility change   
                document.addEventListener(visibilityChangeEvent, this.handleVisibilityChange.bind(this), false);
            }
        },
        removeVisibilityHandlers: function removeVisibilityHandlers() {
            document.removeEventListener(this.get('visibilityChangeEvent'), this.handleVisibilityChange.bind(this), false);
        },
        handleVisibilityChange: function handleVisibilityChange() {
            if (document[this.get('hiddenAttr')]) {
                // videoElement.pause();
            } else {
                // videoElement.play();

                // RESUME the video, seek to end
                var video = this.get('video');
                console.log('Forcing video ahead to ' + (video.duration - 1) + ' from ' + video.currentTime);
                video.currentTime = video.duration - 1;
            }
        },


        actions: {
            forcePlay: function forcePlay() {
                this.set('showPlayOverlay', false);
                this.get('video').play();
            }
        }
    });
});