define('bc-frontend/mixins/test-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    dsUpdates: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var dsUpdates = this.get('dsUpdates');
      dsUpdates.activateTest(controller, model.get('id'));
    },
    deactivate: function deactivate() {
      this._super();

      var dsUpdates = this.get('dsUpdates');
      dsUpdates.deactivateTest();
    }
  });
});