define('bc-frontend/tests/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      includeArchived: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var qParams = {
        owned: true
      };
      if (params.includeArchived) {
        qParams.with_archived = true;
      }
      return this.get('store').query('test', qParams);
    }
  });
});