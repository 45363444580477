define('bc-frontend/components/tests/manage-sharing-dialog/component', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      Component = Ember.Component;


  var PERM_MAPPING = {
    readonly: ['read_test'],
    readwrite: ['read_test', 'change_test'],
    admin: ['read_test', 'change_test', 'delete_test', 'manage_test']
  };

  exports.default = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),
    store: inject.service(),

    updateStamp: null,
    isPending: false,

    collaborators: Ember.computed('model.id', 'updateStamp', function () {
      var modelId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/tests/' + modelId + '/get_collaborators';

      this.get('updateStamp');

      var req = ajax.request(requestUri).then(function (response) {
        return response.tests.map(function (item) {
          if (item.name) {
            item.displayName = item.name;
          } else {
            item.displayName = item.email;
          }
          return item;
        });
      }, function () {
        alert("Failed to load collaborators. Please try again later.");
      });

      return _emberData.default.PromiseArray.create({
        promise: req
      });
    }),

    addCollaborator: function addCollaborator() {
      var self = this,
          modelId = this.get('model.id'),
          ajax = this.get('ajax'),
          email = this.get('newEmail'),
          requestUri = '/api/tests/' + modelId + '/add_collaborator';

      this.set('isPending', true);

      var payload = {
        test: {
          email: email,
          permissions: ['read_test']
        }
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.set('newEmail', '');
        self.set('updateStamp', Date.now());
      }, function () {
        alert('Failed to add collaborator. Please try again momentarily.');
      }).finally(function () {
        self.set('isPending', false);
      });
    },
    setPermission: function setPermission(userId, permission) {
      var self = this,
          modelId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/tests/' + modelId + '/change_collaborator';

      this.set('isPending', true);

      var payload = {
        test: {
          user: userId,
          permissions: PERM_MAPPING[permission]
        }
      };

      ajax.request(requestUri, {
        method: 'put',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.set('updateStamp', Date.now());
      }, function () {
        alert('Failed to change permissions. Please try again momentarily.');
      }).finally(function () {
        self.set('isPending', false);
      });
    },
    removeCollaborator: function removeCollaborator(userId) {
      var self = this,
          modelId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/tests/' + modelId + '/remove_collaborator';

      this.set('isPending', true);

      var payload = {
        test: {
          user: userId
        }
      };

      ajax.request(requestUri, {
        method: 'delete',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.set('updateStamp', Date.now());
      }, function () {
        alert('Failed to remove collaborator. Please try again momentarily.');
      }).finally(function () {
        self.set('isPending', false);
      });
    },


    actions: {
      close: function close() {
        this.sendAction('onClose');
      },
      addCollaborator: function addCollaborator() {
        this.addCollaborator();
      },
      setPermission: function setPermission(collaborator, permissions) {
        this.setPermission(collaborator.id, permissions);
      },
      removeCollaborator: function removeCollaborator(collaborator) {
        this.removeCollaborator(collaborator.id);
      }
    }
  });
});