define('bc-frontend/test/photobeam/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'bc-frontend/mixins/schema-route-mixin'], function (exports, _authenticatedRouteMixin, _schemaRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _schemaRouteMixin.default, /*DataSetRouteMixin, */{
    schemaId: null,

    model: function model(params) {
      this.set('schemaId', params.schema_id);

      return this.get('store').query('dataSet', {
        test: this.modelFor('test').get('id'),
        schema: params.schema_id
      });
    },


    setupController: function setupController(controller, model) {
      // this only gets called on initial load and *not* on refresh
      this._super(controller, model);

      // do stuff that should happen on refresh
      controller.set('schema', this.get('schemaId'));
    }
  });
});