define('bc-frontend/data-set/model', ['exports', 'bc-frontend/config/environment', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _environment, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    mqttTopics: Ember.inject.service(),

    test: (0, _relationships.belongsTo)('test'),
    // TODO
    subject: (0, _attr.default)('string'),

    url: (0, _attr.default)('string'),
    thumbnailUrl: (0, _attr.default)('string'),

    start: (0, _attr.default)('date'),
    end: (0, _attr.default)('date'),
    last: (0, _attr.default)('date'),

    clearing: (0, _attr.default)('date'),
    collecting: (0, _attr.default)('date'),
    complete: (0, _attr.default)('date'),
    converting: (0, _attr.default)('date'),
    created: (0, _attr.default)('date'),
    inserting: (0, _attr.default)('date'),
    updated: (0, _attr.default)('date'),

    originData: (0, _relationships.belongsTo)('data-set', {
      inverse: null
    }),

    originDevice: (0, _relationships.belongsTo)('device', {
      inverse: null
    }),

    // typeSlug: attr('string'),
    metaData: (0, _attr.default)(),

    dataInternalUri: (0, _attr.default)('string'),
    dataPublicUri: (0, _attr.default)('string'),

    duration: (0, _attr.default)(),
    status: (0, _attr.default)('string'),
    substate: (0, _attr.default)('string'),
    reason: (0, _attr.default)('string'),

    schema: (0, _relationships.belongsTo)('dataSetSchema', {
      inverse: null
    }),

    siblingDataSets: (0, _attr.default)('string', {
      defaultValue: '{}'
    }),

    canExport: (0, _attr.default)('boolean'),
    isRealtime: (0, _attr.default)('boolean'),
    realtimeId: (0, _attr.default)('string'),
    realtimeUri: (0, _attr.default)('string'),

    realtimeEndpoint: (0, _attr.default)('string'),
    realtimeTopic: (0, _attr.default)('string'),

    modelUpdatesEndpoint: (0, _attr.default)('string'),
    modelUpdatesTopic: (0, _attr.default)('string'),

    isAppending: Ember.computed.equal('status', 'append'),
    isClearing: Ember.computed.equal('status', 'clearing'),
    isInserting: Ember.computed.equal('status', 'inserting'),
    isCollecting: Ember.computed.equal('status', 'collecting'),
    isQueued: Ember.computed.equal('status', 'queued'),
    isAnalyzing: Ember.computed.equal('status', 'analyzing'),
    isConverting: Ember.computed.equal('status', 'converting'),
    isRealtimeAppending: Ember.computed.and('isRealtime', 'isAppending'),
    isReceiving: Ember.computed.or('isRealtimeAppending', 'isClearing', 'isInserting', 'isCollecting'),

    isOrigin: Ember.computed.not('originData.id'),
    isDestination: Ember.computed.not('isOrigin'),
    isLiveOrigin: Ember.computed.and('isOrigin', 'isReceiving'),
    isLiveDestination: Ember.computed.and('isDestination', 'isReceiving'),

    realSiblings: computed('siblingDataSets', function () {
      var siblingDataSets = this.get('siblingDataSets');
      if (siblingDataSets) {
        return JSON.parse(siblingDataSets);
      }
      return {};
    }),

    playbackUri: computed('url', function () {
      var url = this.get('url');

      if (!url) return null;

      if (url.indexOf("://") >= 0) {
        return url;
      }

      var dataHost = _environment.default['apiHost'];

      return '' + dataHost + url;
    }),

    thumbUri: computed('thumbnailUrl', function () {
      return this.get('thumbnailUrl');
    }),

    displayLabel: (0, _attr.default)('string'),

    lastSample: computed(function () {
      return {};
    }),

    // Realtime/MQTT functionality

    updateWatchers: Ember.computed(function () {
      return Ember.A([]);
    }),

    updateWatcherHandlers: Ember.computed(function () {
      return Ember.A([]);
    }),

    dataWatchers: Ember.computed(function () {
      return Ember.A([]);
    }),

    dataWatcherHandlers: Ember.computed(function () {
      return Ember.A([]);
    }),

    watchUpdates: function watchUpdates(reference, callback) {
      var updateWatchers = this.get('updateWatchers'),
          updateWatcherHandlers = this.get('updateWatcherHandlers');

      if (updateWatchers.length <= 0) {
        var mqttTopics = this.get('mqttTopics');
        mqttTopics.subscribe(this, this.get('modelUpdatesTopic'));
      }

      updateWatchers.pushObject(reference);
      if (callback) {
        updateWatcherHandlers.pushObject(callback);
      }
    },
    unwatchUpdates: function unwatchUpdates(reference, callback) {
      var updateWatchers = this.get('updateWatchers'),
          updateWatcherHandlers = this.get('updateWatcherHandlers');

      updateWatchers.removeObject(reference);
      if (callback) {
        updateWatcherHandlers.removeObject(callback);
      }

      if (updateWatchers.legnth <= 0) {
        var mqttTopics = this.get('mqttTopics');
        mqttTopics.unsubscribe(this, this.get('modelUpdatesTopic'));
      }
    },
    watchData: function watchData(reference, callback) {
      var dataWatchers = this.get('updateWatchers'),
          dataWatcherHandlers = this.get('updateWatcherHandlers');

      dataWatchers.removeObject(reference);
      if (callback) {
        dataWatcherHandlers.removeObject(callback);
      }

      if (dataWatchers.legnth <= 0) {
        var mqttTopics = this.get('mqttTopics');
        mqttTopics.unsubscribe(this, this.get('realtimeTopic'));
      }
    },
    unwatchData: function unwatchData(reference, callback) {
      var dataWatchers = this.get('updateWatchers'),
          dataWatcherHandlers = this.get('updateWatcherHandlers');

      dataWatchers.removeObject(reference);
      if (callback) {
        dataWatcherHandlers.removeObject(callback);
      }

      if (dataWatchers.legnth <= 0) {
        var mqttTopics = this.get('mqttTopics');
        mqttTopics.unsubscribe(this, this.get('realtimeTopic'));
      }
    },
    onMessageArrived: function onMessageArrived(message) {
      var _this = this;

      var messageObj = JSON.parse(message.payloadString),
          camelizedObj = new Ember.Object({}),
          refreshModel = false;

      if (message.destinationName == this.get('modelUpdatesTopic')) {
        Object.keys(messageObj).forEach(function (key) {
          camelizedObj.set(key.camelize(), messageObj[key]);
          if (key == 'status' && messageObj[key] == 'completed') {
            refreshModel = true;
          }
        });

        Object.keys(camelizedObj).forEach(function (key) {
          if (_this[key].meta().type == 'date') {
            camelizedObj[key] = new Date(camelizedObj[key]);
          }
        });

        this.setProperties(camelizedObj);

        var updateWatcherHandlers = this.get('updateWatcherHandlers');

        updateWatcherHandlers.forEach(function (handler) {
          handler(camelizedObj);
        });

        if (refreshModel) {
          this.reload();
        }
      } else {
        var dataWatcherHandlers = this.get('dataWatcherHandlers');

        dataWatcherHandlers.forEach(function (handler) {
          handler(messageObj);
        });
      }

      console.log(messageObj);
    }
  });
});