define('bc-frontend/mixins/schema-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    dsUpdates: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var dsUpdates = this.get('dsUpdates');
      dsUpdates.activateSchema(controller);
    },
    deactivate: function deactivate() {
      this._super();

      var dsUpdates = this.get('dsUpdates');
      dsUpdates.deactivateSchema();
    }
  });
});