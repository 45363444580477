define('bc-frontend/test/ability', ['exports', 'ember-can'], function (exports, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCan.Ability.extend({
    canManage: Ember.computed('model.myPermissions', function () {
      var permissions = this.get('model.myPermissions');
      return permissions && permissions.indexOf('manage_test') >= 0;
    }),
    canChange: Ember.computed('model.myPermissions', function () {
      var permissions = this.get('model.myPermissions');
      return permissions && permissions.indexOf('change_test') >= 0;
    }),
    canRead: Ember.computed('model.myPermissions', function () {
      var permissions = this.get('model.myPermissions');
      return permissions && permissions.indexOf('read_test') >= 0;
    }),
    canDelete: Ember.computed('model.myPermissions', function () {
      var permissions = this.get('model.myPermissions');
      return permissions && permissions.indexOf('delete_test') >= 0;
    })
  });
});