define('bc-frontend/components/dataset-detail/layouts/manual-scoring-data/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    video: Ember.computed.alias('dataSet.realSiblings.video'),
    configuration: Ember.computed.alias('dataSet.realSiblings.manual-scoring-setup'),

    actions: {
      modifiedChanged: function modifiedChanged(flag) {
        this.sendAction('onModifiedChanged', flag);
      }
    }
  });
});