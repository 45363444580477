define('bc-frontend/ajax-no-auth/service', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    // host: ENV['apiHost'],
    contentType: null
  });
});