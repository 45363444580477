define('bc-frontend/test/dataset/controller', ['exports', 'bc-frontend/config/environment', 'moment'], function (exports, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    isAborted: Ember.computed.equal('model.status', 'aborted'),
    isCompleted: Ember.computed.equal('model.status', 'completed'),
    // isAppending: Ember.computed.equal('model.status', 'append'),
    // isCollecting: Ember.computed.equal('model.status', 'collecting'),
    isAppending: Ember.computed.alias('model.isAppending'),
    isClearing: Ember.computed.alias('model.isClearing'),
    isCollecting: Ember.computed.alias('model.isCollecting'),
    isInserting: Ember.computed.alias('model.isInserting'),
    isQueued: Ember.computed.alias('model.isQueued'),
    isAnalyzing: Ember.computed.alias('model.isAnalyzing'),
    isConverting: Ember.computed.alias('model.isConverting'),
    isProcessing: Ember.computed.or('isQueued', 'isAnalyzing', 'isConverting'),
    hasError: Ember.computed.equal('model.status', 'error'),
    isSaving: false,

    // isReceiving: Ember.computed.or('isAppending', 'isCollecting'),
    isReceiving: Ember.computed.alias('model.isReceiving'),
    showTimer: Ember.computed.and('isReceiving', 'model.lastSample'),
    startTime: null,

    shouldShowDownload: Ember.computed.and('model.canExport', 'isCompleted'),
    shouldShowAbort: Ember.computed.or('isCreated', 'isCalibrating', 'isClearing', 'isCleared', 'isInserting', 'isInserted', 'isReceiving'),
    modelChanged: false,
    preventTransition: Ember.computed.alias('modelChanged'),

    // TODO: find a way to pull in updates from other bulk capture channels
    shouldUpdateStations: null,

    onAppendStarted: function onAppendStarted() /*data*/{
      // this.set('elapsed', data.elapsed);
      // this.set('thumbnailStamp', new Date());

      this.get('model').reload();
    },
    onAppend: function onAppend(data) {
      var model = this.get('model');

      // if (model.isRealtime) {
      this.set('startTime', (0, _moment.default)(data.firstTimeStamp));
      model.set('lastSample', data.payload);
      // }
    },


    elapsedTimeDisplay: Ember.computed('model.lastSample', 'startTime', function () {
      var startTime = this.get('startTime'),
          lastTime = (0, _moment.default)(this.get('model.lastSample.t')),
          diff = lastTime.diff(startTime);

      return _moment.default.duration(diff).format("HH:mm:ss", { trim: false });
    }),

    totalTimeDisplay: Ember.computed('model.duration', function () {
      var duration = this.get('model.duration');
      // debugger;

      return _moment.default.duration(duration, "seconds").format("HH:mm:ss", { trim: false });
    }),

    onModelUpdate: function onModelUpdate(data) {
      if (data.hasOwnProperty('status')) {
        if (data.status == 'completed') {
          // TODO: find a way to grab the next state, etc.
          this.onCollectionCompleted(data);
        } else if (data.status == 'converting') {
          this.onSavePending();
        }
      }
    },
    onCollectionCompleted: function onCollectionCompleted(data) {
      this.setProperties({
        isSaving: false,
        showConfirmAbort: false,
        showConfirmSkip: false
      });

      if (data.status === 'completed') {
        if (data.next) {
          this.set('showNextSubject', true);
        } else {
          this.transitionToRoute('test');
        }
      } else {
        this.set('showStatusDialog', true);
      }
    },
    onSavePending: function onSavePending() {
      this.setProperties({
        isSaving: true,
        showConfirmAbort: false,
        showConfirmSkip: false
      });
    },


    exportLink: Ember.computed('model', 'session.session.authenticated.token', function () {
      var token = this.get('session.session.authenticated.token'),
          dataSetId = this.get('model.id'),
          dataHost = _environment.default['apiHost'];

      var requestObj = {
        'token': token
      };
      var q = btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(requestObj))));

      return dataHost + '/api/dataSets/' + dataSetId + '/csv?q=' + q;
    }),

    actions: {
      transition: function transition(route) {
        this.transitionToRoute(route);
      },
      closeCapture: function closeCapture(refresh) {
        this.set('showNextSubject', false);
        if (refresh) this.transitionToRoute('test.photobeam', this.get('model.test'), this.get('model.schema.id'));
      }
    }
  });
});