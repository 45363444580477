define('bc-frontend/components/verb-chart/segment-spacer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':segment'],
    attributeBindings: ['style'],

    style: Ember.computed('segment.width', function () {
      var width = this.get('segment.width') * 3 / 4;
      return Ember.String.htmlSafe('width: ' + width + '%;');
    })
  });
});