define('bc-frontend/components/tests/download-warning-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    exportLink: null, // should be supplied as param

    actions: {
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});