define('bc-frontend/mqtt-topics/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var _Paho = Paho,
        mqtt = _Paho.MQTT;


    var getClientId = function getId() {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (var i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    };

    // const ClientId = getClientId();

    exports.default = Ember.Service.extend({
        clients: Ember.computed(function () {
            return Ember.A([]);
        }),

        topicMap: Ember.computed(function () {
            return new Ember.Object({});
        }),

        subscribe: function subscribe(dataset, topic) {
            var clients = this.get('clients'),
                topicMap = this.get('topicMap'),
                candidate = null;

            clients.forEach(function (clientObject) {
                if (clientObject.subscriptions.length < 50) {
                    candidate = clientObject;
                    //break;
                }
            });

            if (!candidate) {
                var mqttEndpoint = dataset.get('realtimeEndpoint') || dataset.get('modelUpdatesEndpoint');

                // then connect
                if (!mqttEndpoint) {
                    console.warn('No endpoint supplied for dataset ' + dataset.get('id') + '; cannot watch updates');
                    return;
                }

                var client = this.connect(mqttEndpoint, getClientId());
                candidate = new Ember.Object({
                    client: client,
                    connected: false,
                    subscriptions: Ember.A([])
                });
                clients.pushObject(candidate);
            }

            topicMap[topic] = dataset;
            candidate.subscriptions.pushObject(topic);

            if (candidate.connected) {
                // TODO: subcsribe to topic
                candidate.client.subscribe(topic);
            }
        },
        unsubscribe: function unsubscribe(dataset, topic) {
            var clients = this.get('clients'),
                topicMap = this.get('topicMap'),
                candidate = null;

            clients.forEach(function (clientObject) {
                if (clientObject.subscriptions.indexOf(topic) >= 0) {
                    candidate = clientObject;
                    //break;
                }
            });

            if (candidate) {
                candidate.client.unsubscribe(topic);
                candidate.subscriptions.removeObject(topic);
            }

            delete topicMap[topic];
        },
        connect: function connect(url, clientId) {
            var _this = this;

            var client = new mqtt.Client(url, clientId);
            client.connect({
                onFailure: function onFailure() {},
                onSuccess: function onSuccess() {
                    var clients = _this.get('clients');

                    clients.forEach(function (clientObject) {
                        if (clientObject.client == client) {
                            clientObject.subscriptions.forEach(function (topic) {
                                client.subscribe(topic);
                            });
                            clientObject.set('connected', true);
                        }
                    });
                },
                cleanSession: false
            });

            client.onMessageArrived = function (message) {
                return _this.onMessageArrived(message);
            };
            client.onConnectionLost = this.onConnectionLost;
            client.disconnectedPublishing = true;
            client.disconnectedBufferSize = 100000;

            return client;
        },
        onMessageArrived: function onMessageArrived(message) {
            var topicMap = this.get('topicMap'),
                dataset = topicMap[message.destinationName];

            dataset.onMessageArrived(message);
        },
        onConnectionLost: function onConnectionLost(response) {
            console.error("Connection lost!");
        }
    });
});