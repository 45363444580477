define('bc-frontend/utils/modifier-key-codes', ['exports', 'ember-key-manager/utils/modifier-key-codes'], function (exports, _modifierKeyCodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _modifierKeyCodes.default;
    }
  });
});