define('bc-frontend/components/video-calibration/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    ajax: inject.service(),
    store: inject.service(),

    // supplied via param
    test: null,
    model: null,

    dataModified: false,
    isSaving: false,

    latestCalibration: computed.alias('model.firstObject'),
    latestIsValid: computed.equal('latestCalibration.status', 'completed'),
    dataSetCount: computed.alias('model.length'),
    hasData: computed.gte('dataSetCount', 1),

    showManualCalibration: computed.reads('latestCalibration.metaData.manual'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initialize();
    },
    initialize: function initialize() {},
    saveChanges: function saveChanges() {
      var self = this;

      if (!this.get('manualComponent').validateMap()) {
        return;
      }

      this.set('isSaving', true);
      this.get('manualComponent').save().then(function () {
        self.set('isSaving', false);
        self.set('dataModified', false);
      });
    },


    actions: {
      saveChanges: function saveChanges() {
        this.saveChanges();
      }
    }
  });
});