define('bc-frontend/components/verb-chart/segment/component', ['exports', 'bc-frontend/utils/color-generator'], function (exports, _colorGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':segment', 'segment.state:segment-active', 'segment.change.selected:segment-selected'],
    attributeBindings: ['style'],

    segmentVerb: Ember.computed.alias('segment.verb'),
    instantaneous: Ember.computed.alias('segmentVerb.instantaneous'),
    associatedChange: Ember.computed.alias('segment.change'),
    state: Ember.computed.alias('segment.state'),
    isSelected: Ember.computed.alias('associatedChange.selected'),

    color: Ember.computed('segmentVerb.key', function () {
      return (0, _colorGenerator.default)(this.get('segmentVerb.key'));
    }),

    click: function click() {
      this.sendAction('onClick', this.get('segment'));
    },


    style: Ember.computed('segment.width', 'instantaneous', 'isSelected', 'color', 'state', function () {
      var width = this.get('segment.width');
      var selected = this.get('isSelected'),
          state = this.get('state');

      if (this.get('instantaneous')) {
        width = width / 4;
      }

      if (!selected && state) {
        var color = this.get('color');
        return Ember.String.htmlSafe('width: ' + width + '%;background-color:' + color + ';');
      } else {
        return Ember.String.htmlSafe('width: ' + width + '%;');
      }
    })
  });
});