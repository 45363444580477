define('bc-frontend/components/tests/status-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    hadError: Ember.computed.equal('model.status', 'error'),
    wasAborted: Ember.computed.equal('model.status', 'aborted'),

    actions: {
      close: function close() {
        this.sendAction('onClose');
      }
    }
  });
});