define('bc-frontend/dashboard/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    userSession: Ember.inject.service(),

    connectedDevices: Ember.computed.alias('userSession.user.numDevices')
  });
});