define('bc-frontend/services/key-manager', ['exports', 'ember-key-manager/services/key-manager'], function (exports, _keyManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _keyManager.default;
    }
  });
});