define('bc-frontend/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-cli-routing-service/routes/transition-to-listener'], function (exports, _applicationRouteMixin, _transitionToListener) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transitionToListener.default.extend(_applicationRouteMixin.default, {
    dsUpdates: Ember.inject.service(),

    sessionInvalidated: function sessionInvalidated() {
      // default invalidation sometimes leaves the last route
      this.transitionTo('/');
      window.location.replace('/');
    },
    activate: function activate() {
      this.get('dsUpdates').initialize();
    }
  });
});