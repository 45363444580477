define('bc-frontend/data-set-schema/supports-sampling', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Object$create;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Object.create((_Ember$Object$create = {
    'spontaneous-alternation': false,
    'analysis': true,
    'photobeam-activity': true,
    'centroid': false,
    'manual-scoring-analysis': false
  }, _defineProperty(_Ember$Object$create, 'photobeam-activity', true), _defineProperty(_Ember$Object$create, 'zone-sequence', false), _defineProperty(_Ember$Object$create, 'zoned-analysis', true), _Ember$Object$create));
});