define('bc-frontend/components/device-preview/behaviorcloud-camera/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flashSupport: Ember.inject.service(),
    model: Ember.computed.alias('device')
  });
});