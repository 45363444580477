define('bc-frontend/components/video-calibration/empty-state/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    isRecorded: computed.alias('test.liveTest'),
    isUploaded: computed.not('isRecorded'),

    needsVideo: computed.not('model.meta.backgroundUri'),

    actions: {
      startCalibration: function startCalibration() {
        this.sendAction('onAutoCalibrate');
      },
      showManualCalibration: function showManualCalibration() {
        this.sendAction('onManualCalibrate');
      }
    }
  });
});