define('bc-frontend/activatepw/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      token: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var user_id = params.user_id,
          token = params.token;


      return {
        user_id: user_id,
        token: token
      };
    }
  });
});