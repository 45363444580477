define('bc-frontend/settings/billing/controller', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    userSession: Ember.inject.service(),
    forceUpdate: 0,
    showCancelDialog: false,

    billingSummary: Ember.computed('userSession.userId', 'forceUpdate', function () {
      var ajax = this.get('ajax'),
          userId = this.get('userSession.userId');

      this.get('forceUpdate');

      return _emberData.default.PromiseObject.create({
        promise: ajax.request('/api/users/' + userId + '/get_billing_summary').then(function (response) {
          return response.user;
        })
      });
    }),

    planData: Ember.computed('userSession.userId', 'forceUpdate', function () {
      var ajax = this.get('ajax'),
          userId = this.get('userSession.userId');

      this.get('forceUpdate');

      return _emberData.default.PromiseObject.create({
        promise: ajax.request('/api/users/' + userId + '/get_plan_data').then(function (response) {
          return response.user;
        })
      });
    }),

    hasPlan: Ember.computed.alias('planData.hasPlan'),
    planAmount: Ember.computed.alias('planData.planAmount'),
    planName: Ember.computed.alias('planData.planName'),
    planInterval: Ember.computed.alias('planData.planInterval'),
    planRenewalDate: Ember.computed.alias('planData.planRenewalDate'),
    showSubscribe: Ember.computed.not('planData.canCharge'),
    showCancel: Ember.computed.alias('planData.canCancel'),
    isCanceling: Ember.computed('showCancel', 'showSubscribe', function () {
      return !this.get('showCancel') && !this.get('showSubscribe');
    }),
    showSubscriptionDetails: Ember.computed.alias('planData.canCharge'),

    billableTimeSeconds: Ember.computed.alias('billingSummary.billableTime'),
    billableTimeHours: Ember.computed('billableTimeSeconds', function () {
      return this.get('billableTimeSeconds') / 3600.0;
    }),
    remainingTimeSeconds: Ember.computed.alias('billingSummary.remainingTime'),
    remainingTimeHours: Ember.computed('remainingTimeSeconds', function () {
      return this.get('remainingTimeSeconds') / 3600.0;
    }),

    cancelSubscription: function cancelSubscription() {
      var self = this,
          ajax = this.get('ajax'),
          userId = this.get('userSession.userId'),
          forceUpdate = this.get('forceUpdate'),
          url = '/api/users/' + userId + '/cancel_subscription';

      ajax.request(url, {
        method: 'post'
      }).then(function () {
        self.set('forceUpdate', forceUpdate + 1);
      }, function () {
        alert("Failed to cancel subscription. Please try again shortly.");
      });
    },
    processStripeToken: function processStripeToken(token) {
      var self = this,
          ajax = this.get('ajax'),
          userId = this.get('userSession.userId'),
          url = '/api/users/' + userId + '/create_card',
          forceUpdate = this.get('forceUpdate'),
          payload = {
        user: {
          card: token.id
        }
      };

      ajax.request(url, {
        method: 'post',
        contentType: 'application/json',
        data: JSON.stringify(payload)
      }).then(function () {
        self.set('forceUpdate', forceUpdate + 1);
      }, function () {
        alert("Failed to subscribe. Please try again shortly.");
      });
    },


    // updateStripeToken(token) {
    //   let self = this,
    //       ajax = this.get('ajax'),
    //       brand = this.get('brand'),
    //       brandId = brand.get('id'),
    //       url = `/api/brands/${brandId}/update_billing`,
    //       payload = {
    //         brand: {
    //           token: token.id
    //         }
    //       };
    //
    //   ajax.request(url, {
    //     method: 'post',
    //     contentType: 'application/json',
    //     data: JSON.stringify(payload)
    //   }).then(function(result) {
    //     self.get('store').pushPayload(result);
    //   }, function() {
    //     alert("Failed to subscribe. Please try again shortly.");
    //   });
    // },

    actions: {
      cancelSubscription: function cancelSubscription() {
        this.set('showCancelDialog', false);
        this.cancelSubscription();
      },
      processStripeToken: function processStripeToken(token) {
        this.processStripeToken(token);
      }
    }
  });
});