define('bc-frontend/components/manual-scoring-configuration-editor/edit-shortcut-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    shortcut: Ember.computed.reads('behavior.key'),

    actions: {
      confirm: function confirm() {
        var behavior = this.get('behavior'),
            shortcut = this.get('shortcut');

        this.sendAction('onConfirm', behavior, shortcut);
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});